import "devextreme/viz/pie_chart";
import { IPieChartSettings } from "../../../interfaces/advancedreporting/interfaces";
import { TileFactory } from "../../dashboards/tilefactory";
import { TileSettingsBase } from "../../dashboards/tilesettingsbase";
import { Tile_ChartBase } from "./tile_chart";
import { ReportingTileSettings } from "./tile_reportingbase";
export class Tile_PieChart extends Tile_ChartBase {
  protected Type = "pie";
  constructor(tileID: string, settings: Partial<ReportingTileSettings>, settingsClassRef: typeof TileSettingsBase = ReportingTileSettings, PreviewMode: boolean = false, ShowAdvancedSettings: boolean = false) {
    super(tileID, settings, settingsClassRef, PreviewMode, ShowAdvancedSettings);
  }
  public GetAllSettingsFormItems(form?: DevExpress.ui.dxForm) {
    const LineChartTile = this;
    const d: JQueryDeferred<any> = $.Deferred();
    const PieSpecificFormItems: DevExpress.ui.dxFormGroupItem = {
      caption: "Pie Specific Settings",
      itemType: "group",
      items: [
        {
          dataField: "SegmentGroupingMode",
          editorType: "dxSelectBox",
          editorOptions: {
            items: [
              {
                display: "None",
                value: "none",
              }, {
                display: "Value Threshold",
                value: "smallValueThreshold",
              }, {
                display: "Top N",
                value: "topN",
              },
            ],
            displayExpr: "display",
            valueExpr: "value",
          } as DevExpress.ui.dxSelectBoxOptions,
          helpText: "Value Threshold will combine all pie segments whose value is below what is set in Segment Grouping Value. Top N will combine all pie segments that are not in the top 'n' values where 'n' = Segment Grouping Value",
        }, {
          dataField: "SegmentGroupingValue",
          editorType: "dxNumberBox",
        }, {
          dataField: "ShowLabels",
          editorType: "dxCheckBox",
          helpText: "Affects the visibility of the chart's labels",
        }, {
          dataField: "LabelLocation",
          editorType: "dxSelectBox",
          editorOptions: {
            items: [
              {
                display: "Outside",
                value: "outside",
              }, {
                display: "Inside",
                value: "inside",
              }, {
                display: "Columns",
                value: "columns",
              },
            ],
            displayExpr: "display",
            valueExpr: "value",
          } as DevExpress.ui.dxSelectBoxOptions,
          helpText: "Affects the position of the chart's labels",
        }, {
          dataField: "Donut",
          editorType: "dxCheckBox",
          helpText: "Puts a hole in the center of the chart",
        },
      ],
    };
    super.GetAllSettingsFormItems(form ? form : null).done((formItems: DevExpress.ui.dxFormSimpleItem[]) => {
      const temp = formItems.splice(formItems.length - 2, 2, PieSpecificFormItems);
      LineChartTile.FormFields = formItems.concat(temp);
      d.resolve(LineChartTile.FormFields);
    });
    return d.promise();
  }
  public GetSettingsData(): IPieChartSettings {
    const PieChartTile = this;
    const formData = PieChartTile.Form.option("formData");
    if (formData.Donut) {
      formData.Type = "donut";
    } else {
      formData.Type = "pie";
    }
    const settings: IPieChartSettings = super.GetSettingsData() as IPieChartSettings;
    settings.IncomingDataLayout = "Unpivoted";
    settings.LabelLocation = formData.LabelLocation;
    settings.ShowLabels = formData.ShowLabels;
    settings.SegmentGroupingMode = formData.SegmentGroupingMode;
    settings.SegmentGroupingValue = formData.SegmentGroupingValue;
    return (settings);
  }
  public SetSettingsData(settings: IPieChartSettings, UpdateForm = true) {
    if (settings.Type === "donut") {
      (settings as any).Donut = true;
    } else {
      (settings as any).Donut = false;
    }
    return super.SetSettingsData(settings, UpdateForm);
  }
  protected CreateAndAddControl(element) {
    const PieChartTile = this;
    PieChartTile.Options.onDone = (e) => {
      if (element.parent().length && element.parent().width()) {
        e.component.option("size.width", element.parent().width());
      }
    };
    PieChartTile.Control = $("<div />").dxPieChart(PieChartTile.Options as DevExpress.viz.charts.dxPieChartOptions).dxPieChart("instance");
    PieChartTile.SetDataSource();
    element.append(PieChartTile.Control.element());
  }
  protected ConvertSettingsToControlOptions(settings: IPieChartSettings) {
    const ChartTile = this;
    const d: JQueryDeferred<any> = $.Deferred();
    const chartOptions: DevExpress.viz.charts.dxPieChartOptions = {
      series: [{
        argumentField: typeof settings.ArgumentField === "string" ? settings.ArgumentField : settings.ArgumentField[0],
        argumentType: settings.ArgumentType,
        valueField: settings.ValueField,
        label: {
          visible: settings.ShowLabels,
          position: settings.LabelLocation,
          connector: { visible: true },
        },
      }],
      commonSeriesSettings: {
        smallValuesGrouping: {
          mode: settings.SegmentGroupingMode,
          threshold: settings.SegmentGroupingMode === "smallValueThreshold" ? settings.SegmentGroupingValue : undefined,
          topCount: settings.SegmentGroupingMode === "topN" ? settings.SegmentGroupingValue : undefined,
        },
      },
      legend: ChartTile.MapLegend(settings.ShowLegend, settings.LegendLocation),
      type: settings.Type as any,
      resolveLabelOverlapping: "shift",
      elementAttr: {
        id: settings.CssID !== "" ? settings.CssID : undefined,
        class: settings.CssClass !== "" ? settings.CssClass : undefined,
      },
    };
    return d.promise(d.resolve(chartOptions));
  }
}
TileFactory.RegisterTileType("AdvancedReportingPie", "A tile that can be used to display a pie chart.", "fa fa-pie-chart", ReportingTileSettings, Tile_PieChart);
