import * as CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/show-hint.css";
import { AddHandler } from "infrastructure/events/ui_events";
import { EventTypes, eventNameSpace } from "enums/webevents/enums";
import dxTheme from "devextreme/ui/themes";

declare module "codemirror";

export class CodeEditor {

    private Element: JQuery;
    private Mode: string;
    private ExtraHintWords: string[] = [];

    EditorInstance: CodeMirror.Editor;

    constructor(element: JQuery, mode: string, extraHintWords?: string[]) {
        this.Element = element;
        this.Mode = mode;
        if (extraHintWords) {
            this.ExtraHintWords = extraHintWords;
        }

        this.RegisterListeners();
    }

    private RegisterListeners() {
        let cEditor = this;

        AddHandler(
            EventTypes.GeneralEventTypes.themechanged,
            eventNameSpace.modify,
            cEditor.Element,
            cEditor.SetTheme.bind(cEditor)
        );
    }

    Create(): JQueryPromise<CodeMirror.Editor> {
        let cEditor = this;
        let d: JQueryDeferred<CodeMirror.Editor> = $.Deferred();

        $.when(
            cEditor.SetTheme(),
            cEditor.SetMode(cEditor.Mode)
        ).then((editorTheme) => {

            cEditor.EditorInstance = CodeMirror((el) => {
                (cEditor.Element).append(el);
            }, {
                    mode: cEditor.Mode,
                    theme: editorTheme,
                    lineNumbers: true,
                    //matchBrackets: true,
                    smartIndent: false,
                    fixedGutter: true,
                    extraKeys: {
                        F11: function (cm) {
                            cm.setOption("fullScreen", !cm.getOption("fullScreen"));
                        },
                        Esc: function (cm) {
                            if (cm.getOption("fullScreen")) cm.setOption("fullScreen", false);
                        }
                    }
                }
            );

            cEditor.AddAutoComplete();

            if (cEditor.ExtraHintWords && cEditor.ExtraHintWords.length > 0) {
                if (cEditor.Mode === "htmlmixed") {
                    cEditor.Mode = "html";
                }

                cEditor.AddAutoCompleteWords(cEditor.ExtraHintWords);
            }

            d.resolve(cEditor.EditorInstance);
        });

        return d.promise();
    }

    SetMode(mode: string): JQueryPromise<string> {
        let modeNames: string[];
        let hintNames: string[];
        let d: JQueryDeferred<string> = $.Deferred();

        switch (mode) {
            case 'text/x-mssql':
                modeNames = ['sql'];
                hintNames = modeNames;
                break;

            case 'htmlmixed':
                modeNames = ['htmlmixed', 'javascript', 'xml'];
                hintNames = ['html', 'javascript', 'xml'];
                break;
        }

        this.Mode = mode;

        $.when(
            modeNames.forEach(mName => {
                import(`codemirror/mode/${mName}/${mName}`)
            }),
            hintNames.forEach(hName => {
                import(`codemirror/addon/hint/${hName}-hint.js`)
            })
        ).done(() => {
            if (this.EditorInstance) {
                this.EditorInstance.setOption('mode', mode);
            }
            d.resolve(mode);
        });

        return d.promise();
    }

    private SetTheme(): JQueryPromise<string> {
        let d: JQueryDeferred<string> = $.Deferred();
        let editorTheme = "";
        switch (dxTheme.current()) {
            case "generic.greenmist":
            case "generic.greenmist.compact":
                editorTheme = "base16-light";
                break;

            case "generic.darkviolet":
            case "generic.darkviolet.compact":
                editorTheme = "shadowfox";
                break;

            case "generic.darkmoon":
            case "generic.darkmoon.compact":
                editorTheme = "rubyblue";
                break;

            case "generic.dark":
            case "generic.dark.compact":
                editorTheme = "monokai";
                break;

            default:
                editorTheme = "default";
                break;
        }

        if (editorTheme === "default") {
            if (this.EditorInstance) {
                this.EditorInstance.setOption('theme', editorTheme);
            }
            return d.promise(d.resolve(editorTheme));
        } else {
            import(`codemirror/theme/${editorTheme}.css`).then(() => {
                if (this.EditorInstance) {
                    this.EditorInstance.setOption('theme', editorTheme);
                }
                d.resolve(editorTheme);
            });
            return d.promise();
        }

    }

    private AddAutoComplete() {
        let cEditor = this;

        cEditor.EditorInstance.on("keyup", (cm, event) => {
            if (
                !cm.state.completionActive &&
                cEditor.GetEventRaisingKeyCodes(event.keyCode) &&
                !event.ctrlKey
            ) {
                //(<any>CodeMirror.commands).autocomplete(cm, null, { completeSingle: false });
                cm.showHint({ completeSingle: false });
            }
        });
    }

    AddAutoCompleteWords(AutoCompleteWords: string[]) {
        let orig = (<any>CodeMirror).hint[this.Mode];

        this.EditorInstance.setOption('hintOptions', {
            hint: (cm, options) => {
                let cursor = cm.getCursor();
                let currentLine: string = cm.getLine(cursor.line);
                let start: number = cursor.ch;
                let end: number = start;
                let expression = /[\w|<|//|{]+/;

                while (
                    end < currentLine.length &&
                    expression.test(currentLine.charAt(end))
                )
                    ++end;
                while (start && expression.test(currentLine.charAt(start - 1)))--start;

                let curWord: string = start != end && currentLine.slice(start, end);
                let regex = new RegExp("^" + curWord, "i");

                let myList = (!curWord
                    ? AutoCompleteWords
                    : AutoCompleteWords.filter(item => {
                        return item.match(regex);
                    })
                ).sort();

                let origList = orig(cm, options).list;

                let result = {
                    list: myList.concat(origList),
                    from: CodeMirror.Pos(cursor.line, start),
                    to: CodeMirror.Pos(cursor.line, end)
                };

                return result;
            }
        });
    }

    private GetEventRaisingKeyCodes(keyCode) {
        if (
            (keyCode >= 48 && keyCode <= 57) ||
            (keyCode >= 65 && keyCode <= 90) ||
            (keyCode >= 97 && keyCode <= 122) ||
            (keyCode === 188 || keyCode === 190 || keyCode === 219 || keyCode === 221)
        ) {
            return true;
        } else {
            return false;
        }
    }
}