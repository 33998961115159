import { IImageSettings } from "../../../interfaces/advancedreporting/interfaces";
import { TileScopeType } from "../../dashboards/tilebase";
import { TileFactory } from "../../dashboards/tilefactory";
import { TileSettingsBase } from "../../dashboards/tilesettingsbase";
import { ReportingTileBase, ReportingTileSettings } from "./tile_reportingbase";
export class Tile_Image extends ReportingTileBase {
        protected Options;

      protected _ScopeSupportLevel: TileScopeType = TileScopeType.ScopeNotSupported;

        constructor(tileInstanceID: string, settings: Partial<ReportingTileSettings>, settingsClassRef: typeof TileSettingsBase = ReportingTileSettings, PreviewMode: boolean = false, ShowAdvancedSettings: boolean = false) {
            super(tileInstanceID, settings, settingsClassRef, PreviewMode, ShowAdvancedSettings);
        }

        public GetAllSettingsFormItems(form?: DevExpress.ui.dxForm) {
            const ImageTile = this;
            const d: JQueryDeferred<any> = $.Deferred();

            if (form) {
                ImageTile.Form = form;
            }

            ImageTile.FormFields = [
                {
                    caption: "Image Settings",
                    itemType: "group",
                    items: [
                        {
                            dataField: "ImageUrl",
                            editorType: "dxTextBox",
                            colSpan: 2,
                            isRequired: true,
                            helpText: "Link to the desired image. This should be a link that is publicly available",
                        }, {
                            dataField: "Height",
                            editorType: "dxNumberBox",
                            validationRules: [{
                                min: 1,
                                type: "range",
                                message: "Height must be greater than 0",
                            }, {
                                type: "required",
                                message: "Height is required",
                            }],
                            isRequired: true,
                            helpText: "Sets the height of the image",
                        }, {
                            dataField: "Width",
                            editorType: "dxNumberBox",
                            validationRules: [{
                                min: 1,
                                type: "range",
                                message: "Width must be greater than 0",
                            }, {
                                type: "required",
                                message: "Width is required",
                            }],
                            isRequired: true,
                            helpText: "Sets the width of the image",
                        },
                    ],
                }, {
                    caption: "CSS Settings",
                    visible: ImageTile.ShowAdvancedSettings,
                    itemType: "group",
                    items: [
                        {
                            dataField: "CssClass",
                            label: { text: "CSS Class" },
                            editorType: "dxTextBox",
                            isRequired: false,
                            helpText: "Adds the given text as a class on the root element of the control",
                        }, {
                            dataField: "CssID",
                            label: { text: "CSS ID" },
                            editorType: "dxTextBox",
                            isRequired: false,
                            helpText: "Adds the given text as an ID on the root element of the control",
                        },
                    ],
                },
            ];

            return d.promise(d.resolve(ImageTile.FormFields));
        }

        public GetSettingsData(): IImageSettings {
            const ImageTile = this;

            const formData = ImageTile.Form.option("formData");

            const settings: IImageSettings = {
                ImageUrl: formData.ImageUrl,
                Height: formData.Height,
                Width: formData.Width,
                CssClass: formData.CssClass,
                CssID: formData.CssID,
            };

            return settings;
        }

        public SetSettingsData(settings: IImageSettings) {
            const ImageTile = this;

            ImageTile.Form.option("formData", settings);
        }

        protected CreateAndAddControl(element) {
            const ImageTile = this;

            const image = $(`<img src="${ImageTile.Options.imageUrl}" height=${ImageTile.Options.height} width=${ImageTile.Options.width} />`);

            const div: JQuery = $("<div />").css({
                "display": "flex",
                "justify-content": "center",
            }).append(image);

            if (ImageTile.Options.cssClass !== "") {
                div.addClass(ImageTile.Options.cssClass);
            }
            if (ImageTile.Options.cssID !== "") {
                div.attr("id", ImageTile.Options.cssID);
            }

            element.append(div);
        }

        protected ConvertSettingsToControlOptions(settings: IImageSettings) {
            const ImageTile = this;
            const d: JQueryDeferred<any> = $.Deferred();

            const options = {
                imageUrl: settings.ImageUrl,
                height: settings.Height,
                width: settings.Width,
                cssClass: settings.CssClass,
                cssID: settings.CssID,
            };

            return d.promise(d.resolve(options));
        }
    }

TileFactory.RegisterTileType("AdvancedReportingImage", "A tile that can be used to display an image.", "fa fa-picture-o", ReportingTileSettings, Tile_Image);
