import { IHtmlSettings, IRequestedField } from "../../../interfaces/advancedreporting/interfaces";
import { TileFactory } from "../../dashboards/tilefactory";
import { TileSettingsBase } from "../../dashboards/tilesettingsbase";
import { ReportingTileBase, ReportingTileSettings } from "./tile_reportingbase";
import { CodeEditor } from "components/controls/codeeditor";

export class Tile_Repeater extends ReportingTileBase {
    public FormNeedsValidated = false;
    protected Options; // Create interface for html options
    protected NoDataEditor: CodeEditor;
    private HeaderEditor: CodeEditor;
    private ItemEditor: CodeEditor;
    private FooterEditor: CodeEditor;

    constructor(tileInstanceID: string, settings: Partial<ReportingTileSettings>, settingsClassRef: typeof TileSettingsBase = ReportingTileSettings, PreviewMode: boolean = false, ShowAdvancedSettings: boolean = false) {
        super(tileInstanceID, settings, settingsClassRef, PreviewMode, ShowAdvancedSettings);
    }

    public GetAllSettingsFormItems(form?: DevExpress.ui.dxForm) {
        const RepeaterTile = this;
        const d: JQueryDeferred<any> = $.Deferred();

        if (form) {
            RepeaterTile.Form = form;
        }

        const options: DevExpress.ui.dxFormSimpleItem[] = [
            {
                template: (itemData, itemElement) => {
                    const description = $("<p></p>").text("Template editors support HTML and Javascript");

                    itemElement.append(description);
                },
                colSpan: 2,
            }, {
                dataField: "HeaderTemplate",
                template: RepeaterTile.SetFormItemTemplate.bind(RepeaterTile, "Header"),
                colSpan: 2,
                helpText: "Code written here will be run exactly once and placed at the beginning of the resulting control",
            }, {
                dataField: "ItemTemplate",
                template: RepeaterTile.SetFormItemTemplate.bind(RepeaterTile, "Item"),
                colSpan: 2,
                helpText: "Code written here will be run for each record returned by the datasource. Fields data can be used here by giving the field name surrounded in curly brackets (ex. {{FieldName}}).",
            }, {
                dataField: "FooterTemplate",
                template: RepeaterTile.SetFormItemTemplate.bind(RepeaterTile, "Footer"),
                colSpan: 2,
                helpText: "Code written here will be run exactly once and placed at the end of the resulting control",
            }, {
                dataField: "NoDataTemplate",
                template: RepeaterTile.SetFormItemTemplate.bind(RepeaterTile, "NoData"),
                colSpan: 2,
                helpText: "Code written here will be run once if and only if there are no records returned by the datasource",
            },
        ];

        return d.promise(d.resolve(options));
    }

    public GetSettingsData(): IHtmlSettings {
        const RepeaterTile = this;

        const formData = RepeaterTile.Form.option("formData");

        const settings: IHtmlSettings = {
            HeaderTemplate: RepeaterTile.HeaderEditor.EditorInstance.getValue(),
            ItemTemplate: RepeaterTile.ItemEditor.EditorInstance.getValue(),
            FooterTemplate: RepeaterTile.FooterEditor.EditorInstance.getValue(),
            NoDataTemplate: RepeaterTile.NoDataEditor.EditorInstance.getValue(),
            CssClass: "",
            CssID: "",
        };

        return settings;
    }

    public SetSettingsData(settings: IHtmlSettings) {
        const RepeaterTile = this;

        RepeaterTile.Form.option("formData", settings);
        // This should be handled by SetFormitemTemplate already.
    }

    protected CreateAndAddControl(element) {
        const RepeaterTile = this;

        element.append(RepeaterTile.Options);
    }

    protected ConvertSettingsToControlOptions(settings: IHtmlSettings) {
        const RepeaterTile = this;
        const d: JQueryDeferred<any> = $.Deferred();

        RepeaterTile.GetTileData().done((data: any[]) => {

            let options: JQuery;

            if (data.length > 0) {

                options = $("<div />").append(settings.HeaderTemplate);

                for (let i = 0, length = data.length; i < length; i++) {
                    let tempString = settings.ItemTemplate;

                    // Loops through each field of the object and replaces any occurances of the field name with 2 sets of curly braces with its value from that row
                    for (const fieldName in data[i]) {
                        tempString = tempString.replace(new RegExp(`{{${fieldName}}}`, "g"), data[i][fieldName]);
                    }

                    tempString = tempString.replace(/{{ndx}}/g, (i + 1).toString());

                    options.append(tempString);
                }

                options.append(settings.FooterTemplate);
            } else {
                options = $("<div />").append(settings.NoDataTemplate);
            }

            d.resolve(options);
        });

        return d.promise();
    }

    protected SetDataSourceFields(datasourceFields: IRequestedField[]): JQueryPromise<any[]> {
        const RepeaterTile = this;

        const d: JQueryDeferred<any[]> = $.Deferred();

        super.SetDatasourceFields(datasourceFields).done((tileData) => {
            if (RepeaterTile.ItemEditor) {
                RepeaterTile.ItemEditor.AddAutoCompleteWords(RepeaterTile.DatasourceFields.map((a) => `{{${a}}}`));
            }

            d.resolve(tileData);
        });

        return d.promise();
    }

    protected SetFormItemTemplate(section, itemData, itemElement) {
        const RepeaterTile = this;
        itemElement.addClass("reporting-bordered-section");

        const time = setInterval(() => {
            if (RepeaterTile.Form.element().parent().length > 0) {
                clearInterval(time);

                RepeaterTile[`${section}Editor`] = new CodeEditor(
                    itemElement,
                    "htmlmixed",
                    section === "Item"
                        ? RepeaterTile.DatasourceFields.map((a) => `{{${a}}}`)
                        : null
                );

                RepeaterTile[`${section}Editor`].Create().done(() => {

                    const data = RepeaterTile.Form.option("formData");

                    (<CodeEditor>RepeaterTile[`${section}Editor`]).EditorInstance
                        .setSize(itemElement.width(), 300);

                    if (data[`${section}Template`]) {
                        (<CodeEditor>RepeaterTile[`${section}Editor`]).EditorInstance
                            .setValue(data[`${section}Template`]);
                    }
                });

            }
        }, 50);

    }
}

TileFactory.RegisterTileType("AdvancedReportingRepeater", "A tile that can be used to display a repeating section.", "fa fa-code", ReportingTileSettings, Tile_Repeater);
