import { Dialog } from "../../dialogs/dialog";
import { WebEvents } from "../../../infrastructure/events/ui_events";
import { IConditionalFormat, IKpiSettings, IReportingRequestObject, ITileInfo } from "../../../interfaces/advancedreporting/interfaces";
import { TDFRequest } from "../../../services/request";
import { TileFactory, TileInfo } from "../../dashboards/tilefactory";
import { TileSettingsBase } from "../../dashboards/tilesettingsbase";
import { ReportingTileBase, ReportingTileSettings } from "./tile_reportingbase";
import { Tile_Table } from "./tile_table";
import { eventNameSpace, EventTypes } from "../../../enums/webevents/enums";

export class Tile_KPI extends ReportingTileBase {
    public TableFieldNames: string[];
    public TableTile: Tile_Table;

    private ConditionNum: number = 0;
    private ConditionNumsUsed: number[] = [];

    private IsAdvancedExpression: boolean;

    constructor(tileID: string, settings: Partial<ReportingTileSettings>, settingsClassRef: typeof TileSettingsBase = ReportingTileSettings, PreviewMode: boolean = false, ShowAdvancedSettings: boolean = false) {
        super(tileID, settings, settingsClassRef, PreviewMode, ShowAdvancedSettings);

        const KPITile = this;

        KPITile.AddListeners();
    }

    public GetAllSettingsFormItems(form?: DevExpress.ui.dxForm) {
        const KPITile = this;
        const d: JQueryDeferred<any> = $.Deferred();

        if (form) {
            KPITile.Form = form;
        }

        new TDFRequest({
            url: `${KPITile.UrlStub}`,
            type: "GET",
        }).MakeRequest().done((tileList: ITileInfo[]) => {
            tileList = tileList.filter((a) => {
                return a.Settings.TileType === "table";
            });

            KPITile.FormFields = [
                {
                    caption: "KPI Settings",
                    itemType: "group",
                    items: [
                        {
                            dataField: "Title",
                            helpText: "Sets the title of the control",
                        }, {
                            dataField: "TableTileID",
                            label: { text: "Table Tile" },
                            editorType: "dxSelectBox",
                            editorOptions: {
                                dataSource: tileList,
                                displayExpr: "Name",
                                valueExpr: "ID",
                                onSelectionChanged: (e) => {
                                    const fieldNameEditor = KPITile.Form.getEditor("FieldName");

                                    if (e.selectedItem) {

                                        KPITile.TableTile = new Tile_Table(e.selectedItem.ID, {});

                                        KPITile.TableTile.GetTileObject().done((tile) => {
                                            // KPITile.SetDatasourceFields(tile.RequestedFields);

                                            if (fieldNameEditor) {
                                                fieldNameEditor.option("items", tile.RequestedFields.map((a) => a.FieldName));
                                            }
                                        });

                                    }
                                },
                            } as DevExpress.ui.dxSelectBoxOptions,
                            isRequired: true,
                            helpText: "Sets the table tile that data for this KPI will come from. When drilling in, the selected table tile will be shown",
                        }, {
                            dataField: "AllowDrillIn",
                            editorType: "dxCheckBox",
                            colSpan: 2,
                            helpText: "Allows the KPI to open a dialog with a table of data when clicked on",
                        }, {
                            dataField: "FontSize",
                            editorType: "dxNumberBox",
                            editorOptions: {
                                mode: "number",
                                min: 1,
                                max: 70,
                                showSpinButtons: true,
                            } as DevExpress.ui.dxNumberBoxOptions,
                            validationRules: [
                                {
                                    type: "range",
                                    min: 1,
                                    max: 70,
                                    message: "Font Size must be between 1 and 70",
                                }, {
                                    type: "required",
                                    message: "Font Size is required",
                                },
                            ],
                            isRequired: true,
                            helpText: "Sets the font size of the text in this control",
                        },
                    ],
                } as DevExpress.ui.dxFormGroupItem, {
                    caption: "Expression Settings",
                    itemType: "group",
                    items: [{
                        colSpan: 1,
                        itemType: "tabbed",
                        tabPanelOptions: {
                            onInitialized: (e) => {
                                if (KPITile.IsAdvancedExpression) {
                                    (e.component as any).selectItem(1);
                                }
                            },
                        },
                        tabs: [
                            {
                                colCount: 1,
                                title: "Basic",
                                items: [
                                    {
                                        dataField: "Aggregate",
                                        editorType: "dxSelectBox",
                                        editorOptions: {
                                            items: ["Avg", "Count", "Max", "Min", "Sum"],
                                            showClearButton: true,
                                        } as DevExpress.ui.dxSelectBoxOptions,
                                        helpText: "Sets the aggregation to be used on the selected field name to calculate the KPI",
                                    }, {
                                        dataField: "FieldName",
                                        editorType: "dxSelectBox",
                                        editorOptions: {
                                            onInitialized: (e) => {
                                                const id = KPITile.Form.option("formData").TableTileID;

                                                if (id) {
                                                    KPITile.TableTile = new Tile_Table(id, {});

                                                    if (KPITile.TableTile) {
                                                        KPITile.TableTile.GetTileObject().done((tile) => {
                                                            e.component.option("items", tile.RequestedFields.map((a) => a.FieldName));
                                                        });
                                                    }
                                                }
                                            },
                                            showClearButton: true,
                                        } as DevExpress.ui.dxSelectBoxOptions,
                                        helpText: "Sets the field name from the selected table tile to be used to calculate the KPI",
                                    },
                                ],
                            }, {
                                title: "Advanced",
                                items: [
                                    {
                                        dataField: "Expression",
                                        editorType: "dxTextBox",
                                        editorOptions: {
                                            onFocusIn: (e) => {
                                                KPITile.TableTile.GetTileObject().done((tile) => {
                                                    KPITile.GetBookmarksPopup(e.element, tile.RequestedFields.map((a) => a.FieldName));
                                                });
                                            },
                                        } as DevExpress.ui.dxTextAreaOptions,
                                        helpText: "Sets the full expression to be used to calculate the KPI. This field supports aggregate functions (AVG, COUNT, MAX, MIN, SUM) and arithmetic",
                                    },
                                ],
                            },
                        ],
                    } as DevExpress.ui.dxFormTabbedItem],
                } as DevExpress.ui.dxFormGroupItem, {
                    caption: "CSS Settings",
                    visible: KPITile.ShowAdvancedSettings,
                    itemType: "group",
                    items: [
                        {
                            dataField: "CssClass",
                            label: { text: "CSS Class" },
                            editorType: "dxTextBox",
                            isRequired: false,
                            helpText: "Adds the given text as a class on the root element of the control",
                        }, {
                            dataField: "CssID",
                            label: { text: "CSS ID" },
                            editorType: "dxTextBox",
                            isRequired: false,
                            helpText: "Adds the given text as an ID on the root element of the control",
                        },
                    ],
                }, {
                    itemType: "empty",
                }, {
                    caption: "Conditional Formatting",
                    visible: KPITile.ShowAdvancedSettings,
                    itemType: "group",
                    colSpan: 2,
                    colCount: 2,
                    items: [],
                } as DevExpress.ui.dxFormGroupItem,
            ];

            KPITile.GetTileObject().done((tile) => {
                if ((tile.Settings as IKpiSettings).ConditionalFormatting.length > 0) {

                    KPITile.ConditionNum = 0;
                    KPITile.ConditionNumsUsed = [];

                    $.each((tile.Settings as IKpiSettings).ConditionalFormatting, (k, v) => {
                        KPITile.AddCondition(false);
                    });

                    KPITile.UpdateFormFields();

                }

                d.resolve(KPITile.FormFields);
            });

        });

        return d.promise();
    }

    public GetSettingsData() {
        const KPITile = this;

        const formData: IKpiSettings = KPITile.Form.option("formData");

        let expression;

        if (formData.FieldName) {
            expression = `${formData.Aggregate}([${formData.FieldName}])`;
        } else {
            expression = formData.Expression;
        }

        const settings: IKpiSettings = {
            TableTileID: formData.TableTileID,
            Title: formData.Title,
            Expression: expression,
            AllowDrillIn: formData.AllowDrillIn,
            FontSize: formData.FontSize,
            ConditionalFormatting: KPITile.GetConditionSettings(),
        };

        return settings;
    }

    public SetSettingsData(settings: IKpiSettings) {
        const KPITile = this;

        // Checks for simple expression structure (ex. SUM(fieldName))
        const simpleExpressionRegEx = new RegExp("^[A-Za-z]{3,5}\\([\\w\\-]*\\)$");

        if (simpleExpressionRegEx.test(settings.Expression)) {
            const firstParenthesis = settings.Expression.indexOf("(");

            settings.Aggregate = settings.Expression.substring(0, firstParenthesis);
            settings.FieldName = settings.Expression.substring(firstParenthesis + 1, settings.Expression.length - 1);

            KPITile.IsAdvancedExpression = false;
        } else if (settings.Expression && settings.Expression.length > 0) {
            KPITile.IsAdvancedExpression = true;
        }

        $.each(settings.ConditionalFormatting, (k, v) => {
            settings[`Color${k + 1}`] = v.Color;
            settings[`Operation${k + 1}`] = v.Operation;
            settings[`Value${k + 1}`] = v.Value;
        });

        KPITile.Form.option("formData", settings);
    }

    protected CreateAndAddControl(element) {
        const KPITile = this;

        const div: JQuery = $("<div />").css({
            "width": "100%",
            "height": "100%",
            "font-weight": "bold",
            "font-size": KPITile.Options.FontSize,
            "color": KPITile.Options.ConditionalFormatting,
            "text-align": "center",
            "cursor": KPITile.Options.AllowDrillIn ? "pointer" : "default",
        })
            .on("click", () => {
                if (KPITile.Options.AllowDrillIn) {
                    KPITile.ShowKPIDialog();
                }
            });

        div
            .append($("<p>").text(KPITile.Options.Title))
            .append($("<p>").text(KPITile.Options.Value));

        element.append(div);
    }

    protected ConvertSettingsToControlOptions(settings: IKpiSettings) {
        const KPITile = this;
        const d: JQueryDeferred<any> = $.Deferred();

        if (!KPITile.TableTile) {
            KPITile.TableTile = new Tile_Table(settings.TableTileID, {});
        }

        KPITile.TableTile.GetRequestObject().done((requestObj: IReportingRequestObject) => {
            const obj = {
                TileConditions: requestObj.conditions,
                TileId: settings.TableTileID,
                Expression: settings.Expression ? settings.Expression.replace(/{{/g, '[').replace(/}}/g, ']') : '',
            };

            new TDFRequest({
                url: `${KPITile.UrlStub}/KPI/Compute`,
                type: "POST",
                data: obj,
            }).MakeRequest().done((response) => {
                const options = {
                    Value: response,
                    AllowDrillIn: settings.AllowDrillIn,
                    Title: settings.Title,
                    FontSize: settings.FontSize,
                    ConditionalFormatting: KPITile.CheckConditions(response, settings.ConditionalFormatting),
                };

                d.resolve(options);
            });
        });

        return d.promise();
    }

    private ShowKPIDialog() {
        const KPITile = this;

        const element = $("<div />").width("100%");

        const KPIDialog = new Dialog({
            title: "",
            closable: true,
            body: element,
        }, null, false, false);

        KPIDialog.open().done(() => {
            KPITile.TableTile.Render(element);
        });
    }

    private AddCondition(UpdateForm: boolean = true) {
        const KPITile = this;

        KPITile.ConditionNum++;

        const index = KPITile.ConditionNum;

        KPITile.ConditionNumsUsed.push(index);

        (KPITile.FormFields[4] as DevExpress.ui.dxFormGroupItem).items.push({
            caption: `Condition`,
            name: `Condition - ${index}`,
            itemType: "group",
            items: [
                {
                    dataField: `Color${index}`,
                    label: {
                        text: "Color",
                    },
                    editorType: "dxColorBox",
                    helpText: "Sets the color of the KPI's text if this condition is met",
                }, {
                    dataField: `Operation${index}`,
                    label: {
                        text: "Operation",
                    },
                    editorType: "dxSelectBox",
                    editorOptions: {
                        items: ["=", "!=", "<", ">", "<=", ">="],
                    } as DevExpress.ui.dxSelectBoxOptions,
                    isRequired: true,
                    helpText: "Used to compare the value resolved from the expression and the value in this condition",
                }, {
                    dataField: `Value${index}`,
                    label: {
                        text: "Value",
                    },
                    editorType: "dxTextBox",
                    editorOptions: {
                    } as DevExpress.ui.dxTextBoxOptions,
                    isRequired: true,
                },
            ] as Array<DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormButtonItem>,
        });

        if (UpdateForm) {
            KPITile.UpdateFormFields();
        }
    }

    private RemoveCondition(conditionNum: number) {
        const KPITile = this;

        let indexToRemove;

        $.each((KPITile.FormFields[4] as DevExpress.ui.dxFormGroupItem).items, (k, v) => {
            if (v.itemType === "group" && v.name === `Condition - ${conditionNum}`) {
                indexToRemove = k;
            }
        });

        (KPITile.FormFields[4] as DevExpress.ui.dxFormGroupItem).items.splice(indexToRemove, 1);

        KPITile.ConditionNumsUsed.splice(KPITile.ConditionNumsUsed.indexOf(conditionNum), 1);

        KPITile.UpdateFormFields();
    }

    private GetConditionSettings() {
        const KPITile = this;

        const data = KPITile.Form.option("formData");

        const compiledConditionValue = [];

        $.each(KPITile.ConditionNumsUsed, (k, v) => {
            compiledConditionValue.push({
                Color: data[`Color${v}`],
                Operation: data[`Operation${v}`],
                Value: data[`Value${v}`],
            });
        });

        return compiledConditionValue;

    }

    private CheckConditions(value: number, conditions: IConditionalFormat[]) {
        const KPITile = this;
        let resolvedColor;

        for (let i = 0, length = conditions.length; i < length; i++) {
            const conditionString = `${value} ${conditions[i].Operation === "=" ? "===" : conditions[i].Operation} ${conditions[i].Value}`;

            if (eval(conditionString)) {
                resolvedColor = conditions[i].Color;
            }
        }

        return resolvedColor;
    }

    private AddListeners() {
        const KPITile = this;

        WebEvents.Event.AddHandler(EventTypes.DashboardEventTypes.FormReady, eventNameSpace.notify, KPITile.Element, KPITile.AddConditionalFormattingButtons.bind(KPITile));
    }

    private AddConditionalFormattingButtons() {
        const KPITile = this;

        if (KPITile.ShowAdvancedSettings && $(".dx-form-group-caption:contains(Conditional Formatting)").find(".dx-button").length === 0) {

            const AddConditionButton: DevExpress.ui.dxButton = $("<div />").css({
                "margin-left": "10px",
                "border-radius": "50px",
            }).dxButton({
                icon: "fa fa-plus-circle",
                type: "success",
                onClick: (e) => {
                    KPITile.AddCondition();
                },
            } as DevExpress.ui.dxButtonOptions).dxButton("instance");

            AddConditionButton.element()
                .on("mouseenter", () => {
                    AddConditionButton.option("text", "Add New Condition");
                }).on("mouseleave", () => {
                    AddConditionButton.option("text", "");
                });

            $(".dx-form-group-caption:contains(Conditional Formatting)").append(AddConditionButton.element());

            for (let i = 0, length = KPITile.ConditionNumsUsed.length; i < length; i++) {

                const removeIndex = KPITile.ConditionNumsUsed[i];

                const RemoveConditionButton: DevExpress.ui.dxButton = $("<div />").css({
                    "margin-left": "10px",
                    "border-radius": "50px",
                }).dxButton({
                    icon: "fa fa-minus-circle",
                    type: "danger",
                    onClick: (e) => {
                        KPITile.RemoveCondition(removeIndex);
                    },
                } as DevExpress.ui.dxButtonOptions).dxButton("instance");

                RemoveConditionButton.element()
                    .on("mouseenter", () => {
                        RemoveConditionButton.option("text", "Remove This Condition");
                    }).on("mouseleave", () => {
                        RemoveConditionButton.option("text", "");
                    });

                $(".dx-form-group-caption:contains(Condition):not(:first)").eq(i).append(RemoveConditionButton.element());

            }

        }
    }
}

TileFactory.RegisterTileType("AdvancedReportingKpi", "A tile that can be used to display a KPI and opens a table on click.", "fa-info", ReportingTileSettings, Tile_KPI);
