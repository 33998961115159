import { IBaseChartSettings } from "../../../interfaces/advancedreporting/interfaces";
import { TileFactory } from "../../dashboards/tilefactory";
import { TileSettingsBase } from "../../dashboards/tilesettingsbase";
import { Tile_ChartBase } from "./tile_chart";
import { ReportingTileSettings } from "./tile_reportingbase";
export class Tile_GeneralChart extends Tile_ChartBase {
  protected Type = "line";
  constructor(tileID: string, settings: Partial<ReportingTileSettings>, settingsClassRef: typeof TileSettingsBase = ReportingTileSettings, PreviewMode: boolean = false, ShowAdvancedSettings: boolean = false) {
    super(tileID, settings, settingsClassRef, PreviewMode, ShowAdvancedSettings);
  }
  public GetAllSettingsFormItems(form?: DevExpress.ui.dxForm) {
    const GeneralChartTile = this;
    const d: JQueryDeferred<any> = $.Deferred();
    const ChartSettings: DevExpress.ui.dxFormGroupItem = {
      caption: "Chart Settings",
      itemType: "group",
      items: [
        {
          dataField: "Type",
          label: { text: "Chart Type" },
          editorType: "dxSelectBox",
          editorOptions: {
            items: [
              {
                display: "Line",
                value: "line",
              }, {
                display: "Stacked Line",
                value: "stackedline",
              }, {
                display: "Area",
                value: "area",
              }, {
                display: "Stacked Area",
                value: "stackedarea",
              }, {
                display: "Bar",
                value: "bar",
              }, {
                display: "Stacked Bar",
                value: "stackedbar",
              }, {
                display: "Spline",
                value: "spline",
              }, {
                display: "Spline Area",
                value: "splinearea",
              }, {
                display: "Stacked Spline",
                value: "stackedspline",
              }, {
                display: "Stacked Spline Area",
                value: "stackedsplinearea",
              }, {
                display: "Scatter",
                value: "scatter",
              }, {
                display: "Step Area Chart",
                value: "steparea",
              },
            ],
            displayExpr: "display",
            valueExpr: "value",
          } as DevExpress.ui.dxSelectBoxOptions,
          helpText: "Changes the type of chart that is rendered",
        },
      ],
    };
    super.GetAllSettingsFormItems(form ? form : null).done((formItems: DevExpress.ui.dxFormSimpleItem[]) => {
      const temp = formItems.splice(formItems.length - 2, 2, ChartSettings);
      GeneralChartTile.FormFields = formItems.concat(temp);
      d.resolve(GeneralChartTile.FormFields);
    });
    return d.promise();
  }
  public GetSettingsData(): IBaseChartSettings {
    const GeneralChartTile = this;
    const settings = super.GetSettingsData();
    const pivot = GeneralChartTile.FindPivotSetting(settings.ArgumentField, settings.NameField);
    settings.IncomingDataLayout = pivot;
    return (settings);
  }
}
TileFactory.RegisterTileType("AdvancedReportingChart", "A tile that can be used to display a chart.", "fa fa-line-chart", ReportingTileSettings, Tile_GeneralChart);
