import * as moment from "moment";
import {
  IRequestedField,
  ITableColumn,
  ITableSettings
} from "../../../interfaces/advancedreporting/interfaces";
import { TileFactory } from "../../dashboards/tilefactory";
import { TileSettingsBase } from "../../dashboards/tilesettingsbase";
import { Dialog } from "../../dialogs/dialog";
import { Notification } from "../../dialogs/notification";
import { ReportingTileBase, ReportingTileSettings } from "./tile_reportingbase";
import { CloneIT } from "../../../util/allutils";
import "devextreme/ui/data_grid";
import "devextreme/ui/form";
import "devextreme/ui/data_grid";
import "jquery-ui/widgets/draggable";
import "jquery-ui/widgets/droppable";

export class Tile_Table extends ReportingTileBase {
  public FormNeedsValidated = false;

  protected Control: DevExpress.ui.dxDataGrid;
  protected Options: DevExpress.ui.dxDataGridOptions;

  private Columns: ITableColumn[] = [];
  private ColumnGrid: DevExpress.ui.dxDataGrid;

  constructor(
    tileInstanceID: string,
    settings: Partial<ReportingTileSettings>,
    settingsClassRef: typeof TileSettingsBase = ReportingTileSettings,
    PreviewMode: boolean = false,
    ShowAdvancedSettings: boolean = false
  ) {
    super(
      tileInstanceID,
      settings,
      settingsClassRef,
      PreviewMode,
      ShowAdvancedSettings
    );
  }

  public GetAllSettingsFormItems(form?: DevExpress.ui.dxForm) {
    const TableTile = this;
    const d: JQueryDeferred<any> = $.Deferred();

    if (form) {
      TableTile.Form = form;
    }

    TableTile.SetDefaultFormFields();

    TableTile.GetTileObject().done(tile => {
      TableTile.Columns = (tile.Settings as ITableSettings).Columns;

      d.resolve(TableTile.FormFields);
    });

    return d.promise();
  }

  public GetSettingsData(): ITableSettings {
    const TableTile = this;

    const formData = TableTile.Form.option("formData");

    const settings: ITableSettings = {
      Columns: TableTile.Columns,
      DefaultFieldsToVisible: formData.DefaultFieldsToVisible,
      AllowFiltering: formData.AllowFiltering,
      AllowGrouping: formData.AllowGrouping,
      AllowSorting: formData.AllowSorting,
      CssClass: formData.CssClass,
      CssID: formData.CssID
    };

    return settings;
  }

  public SetSettingsData(settings: ITableSettings) {
    const TableTile = this;

    const formData = settings;

    TableTile.Form.option("formData", formData);
  }

  public SetDatasourceFields(datasourceFields: IRequestedField[]) {
    const TableTile = this;

    const d: JQueryDeferred<any[]> = $.Deferred();

    if (datasourceFields.length > 0) {
      TableTile.SetUpdateColumns(datasourceFields);
    }

    super.SetDatasourceFields(datasourceFields).done(tileData => {
      d.resolve(tileData);
    });

    return d.promise();
  }

  protected CreateAndAddControl(element: JQuery) {
    const TableTile = this;

    TableTile.Options.height = TableTile.PreviewMode
      ? window.innerHeight / 2
      : "100%";

    TableTile.Control = $("<div />")
      .dxDataGrid(TableTile.Options)
      .dxDataGrid("instance");

    TableTile.SetDataSource();

    element.append(TableTile.Control.element());
  }

  protected ConvertSettingsToControlOptions(
    settings: ITableSettings
  ): JQueryPromise<DevExpress.ui.dxDataGridOptions> {
    const TableTile = this;
    const d: JQueryDeferred<DevExpress.ui.dxDataGridOptions> = $.Deferred();

    const options: DevExpress.ui.dxDataGridOptions = {
      columns: TableTile.MapColumns(settings.Columns),
      columnAutoWidth: true,
      width: "100%",
      groupPanel: {
        visible: settings.AllowGrouping
      },
      grouping: {
        autoExpandAll: false
      },
      headerFilter: {
        visible: settings.AllowFiltering
      },
      filterRow: {
        visible: settings.AllowFiltering
      },
      sorting: {
        mode: settings.AllowSorting ? "multiple" : "none"
      },
      showBorders: true,
      pager: {
        allowedPageSizes: [10, 20, 50],
        showInfo: true,
        showPageSizeSelector: true
      },
      elementAttr: {
        id: settings.CssID !== "" ? settings.CssID : undefined,
        class: settings.CssClass !== "" ? settings.CssClass : undefined
      }
    };

    return d.promise(d.resolve(options));
  }

  private MapColumns(
    columns: ITableColumn[]
  ): DevExpress.ui.dxDataGridColumn[] {
    const dataGridColumns: DevExpress.ui.dxDataGridColumn[] = [];

    for (let i = 0, length = columns.length; i < length; i++) {
      dataGridColumns.push({
        alignment: columns[i].Alignment || undefined,
        caption: columns[i].Caption,
        dataField: columns[i].FieldName,
        visible: columns[i].Visible,
        dataType: columns[i].DataType as any,
        visibleIndex: columns[i].Index,
        width: columns[i].Width,
        format:
          columns[i].FormatString.charAt(0).toLowerCase() +
          columns[i].FormatString.slice(1).replace(/ /g, "")
      });
    }

    dataGridColumns.push({
      visible: true
    });

    return dataGridColumns;
  }

  private SetDefaultFormFields() {
    const TableTile = this;

    TableTile.FormFields = [
      {
        caption: "Table Settings",
        itemType: "group",
        items: [
          {
            dataField: "AllowSorting",
            editorType: "dxCheckBox",
            colSpan: 2,
            helpText: "Adds the ability to sort the grid"
          },
          {
            dataField: "AllowGrouping",
            editorType: "dxCheckBox",
            colSpan: 2,
            helpText: "Adds the ability to group the grid"
          },
          {
            dataField: "AllowFiltering",
            editorType: "dxCheckBox",
            colSpan: 2,
            helpText: "Adds the ability to filter the grid"
          },
          <any>{
            editorType: "dxButton",
            editorOptions: {
              text: "Configure Columns",
              icon: "fa fa-pencil",
              type: "normal",
              onClick: () => {
                TableTile.ConfigColumnsDialog();
              }
            } as DevExpress.ui.dxButtonOptions,
            colSpan: 2
          }
        ]
      },
      {
        caption: "CSS Settings",
        visible: TableTile.ShowAdvancedSettings,
        itemType: "group",
        items: [
          {
            dataField: "CssClass",
            label: { text: "CSS Class" },
            editorType: "dxTextBox",
            isRequired: false,
            helpText:
              "Adds the given text as a class on the root element of the control"
          },
          {
            dataField: "CssID",
            label: { text: "CSS ID" },
            editorType: "dxTextBox",
            isRequired: false,
            helpText:
              "Adds the given text as an ID on the root element of the control"
          }
        ]
      }
    ];
  }

  private ConfigColumnsDialog() {
    const TableTile = this;

    const origColumns = CloneIT(TableTile.Columns);

    const formatOptions = [
      "Currency",
      "Decimal",
      "Percent",
      "Exponential",
      "Fixed Point",
      "Long Date",
      "Short Date",
      "Month And Day",
      "Month And Year",
      "Long Date Long Time",
      "Short Date Short Time"
    ];

    const form: DevExpress.ui.dxForm = $("<div />")
      .dxForm({
        colCount: 5,
        items: [
          {
            label: { text: "Format String" },
            dataField: "FormatString",
            colSpan: 4,
            editorType: "dxSelectBox",
            editorOptions: {
              disabled: true,
              items: formatOptions,
              acceptCustomValue: true,
              searchEnabled: true
            } as DevExpress.ui.dxSelectBoxOptions,
            helpText:
              'You may choose from the list of presets or create a custom format. (ex. Using "$#,##0.##" will cause "123456.78" to look like "$123,456.78")'
          },
          {
            label: { text: "Use Current Value" },
            dataField: "FormatStringDefault",
            editorType: "dxCheckBox",
            editorOptions: {
              value: true,
              onValueChanged: e => {
                const textbox = form.getEditor(
                  "FormatString"
                ) as DevExpress.ui.dxTextBox;

                textbox.option("disabled", e.value);
              }
            } as DevExpress.ui.dxCheckBoxOptions
          },
          {
            label: { text: "Alignment" },
            dataField: "Alignment",
            editorType: "dxSelectBox",
            colSpan: 4,
            editorOptions: {
              items: ["left", "center", "right"],
              disabled: true
            } as DevExpress.ui.dxSelectBoxOptions
          },
          {
            label: { text: "Use Current Value" },
            dataField: "AlignmentDefault",
            editorType: "dxCheckBox",
            editorOptions: {
              value: true,
              onValueChanged: e => {
                const selectbox = form.getEditor(
                  "Alignment"
                ) as DevExpress.ui.dxSelectBox;

                selectbox.option("disabled", e.value);
              }
            } as DevExpress.ui.dxCheckBoxOptions
          },
          {
            label: { text: "Width" },
            dataField: "Width",
            editorType: "dxTextBox",
            colSpan: 4,
            editorOptions: {
              disabled: true
            } as DevExpress.ui.dxTextBoxOptions
          },
          {
            label: { text: "Use Current Value" },
            dataField: "WidthDefault",
            editorType: "dxCheckBox",
            editorOptions: {
              value: true,
              onValueChanged: e => {
                const textbox = form.getEditor(
                  "Width"
                ) as DevExpress.ui.dxTextBox;

                textbox.option("disabled", e.value);
              }
            } as DevExpress.ui.dxCheckBoxOptions
          },
          {
            label: { text: "Visible" },
            dataField: "Visible",
            editorType: "dxCheckBox",
            colSpan: 4,
            editorOptions: {
              value: true,
              disabled: true
            } as DevExpress.ui.dxCheckBoxOptions
          },
          {
            label: { text: "Use Current Value" },
            dataField: "VisibleDefault",
            editorType: "dxCheckBox",
            editorOptions: {
              value: true,
              onValueChanged: e => {
                const checkbox = form.getEditor(
                  "Visible"
                ) as DevExpress.ui.dxTextBox;

                checkbox.option("disabled", e.value);
              }
            } as DevExpress.ui.dxCheckBoxOptions
          },
          {
            editorType: "dxButton",
            editorOptions: {
              text: "Apply to Selected",
              elementAttr: { class: "primary" },
              onClick: e => {
                const selectedColumns: ITableColumn[] = TableTile.ColumnGrid.getSelectedRowsData();

                const FStringCheck: DevExpress.ui.dxCheckBox = form.getEditor(
                  "FormatStringDefault"
                );
                const AlignmentCheck: DevExpress.ui.dxCheckBox = form.getEditor(
                  "AlignmentDefault"
                );
                const WidthCheck: DevExpress.ui.dxCheckBox = form.getEditor(
                  "WidthDefault"
                );
                const VisibleCheck: DevExpress.ui.dxCheckBox = form.getEditor(
                  "VisibleDefault"
                );

                const editObject: ITableColumn = {};

                if (!FStringCheck.option("value")) {
                  const FString: DevExpress.ui.dxSelectBox = form.getEditor(
                    "FormatString"
                  );
                  (editObject as ITableColumn).FormatString = FString.option(
                    "value"
                  );
                }

                if (!AlignmentCheck.option("value")) {
                  const Alignment: DevExpress.ui.dxSelectBox = form.getEditor(
                    "Alignment"
                  );
                  (editObject as ITableColumn).Alignment = Alignment.option(
                    "value"
                  );
                }

                if (!WidthCheck.option("value")) {
                  const Width: DevExpress.ui.dxTextBox = form.getEditor(
                    "Width"
                  );
                  (editObject as ITableColumn).Width = Width.option("value");
                }

                if (!VisibleCheck.option("value")) {
                  const Visible: DevExpress.ui.dxCheckBox = form.getEditor(
                    "Visible"
                  );
                  (editObject as ITableColumn).Visible = Visible.option(
                    "value"
                  );
                }

                for (
                  let i = 0, length = selectedColumns.length;
                  i < length;
                  i++
                ) {
                  if (editObject.FormatString !== undefined) {
                    selectedColumns[i].FormatString = editObject.FormatString;
                  }
                  if (editObject.Alignment !== undefined) {
                    selectedColumns[i].Alignment = editObject.Alignment;
                  }
                  if (editObject.Width !== undefined) {
                    selectedColumns[i].Width = editObject.Width;
                  }
                  if (editObject.Visible !== undefined) {
                    selectedColumns[i].Visible = editObject.Visible;
                  }
                }

                TableTile.UpdateColumnSettings(selectedColumns);

                TableTile.ColumnGrid.clearSelection();

                TableTile.ColumnGrid.getDataSource().reload();

                new Notification({
                  message: `Updated ${selectedColumns.length} Columns.`,
                  type: "success"
                });
              }
            } as DevExpress.ui.dxButtonOptions
          }
        ]
      } as DevExpress.ui.dxFormOptions)
      .dxForm("instance");

    TableTile.ColumnGrid = $("<div />")
      .dxDataGrid({
        dataSource: TableTile.Columns,
        columns: [
          {
            cellTemplate: () => {
              return $("<div />").addClass("fa fa-arrows-v");
            },
            width: "auto",
            alignment: "center"
          },
          {
            dataField: "FieldName"
          },
          {
            dataField: "Caption"
          },
          {
            dataField: "FormatString",
            lookup: {
              dataSource: formatOptions
            }
          },
          {
            dataField: "Alignment",
            lookup: {
              dataSource: ["left", "center", "right"]
            }
          },
          {
            dataField: "Width"
          },
          {
            dataField: "Visible"
          },
          {
            dataField: "Index",
            visible: false,
            sortOrder: "asc"
          }
        ],
        editing: {
          allowUpdating: true,
          mode: "form",
          form: {
            colCount: 2,
            items: [
              {
                dataField: "Caption"
              },
              {
                dataField: "FormatString",
                editorType: "dxSelectBox",
                editorOptions: {
                  //  items: formatOptions,
                  acceptCustomValue: true,
                  searchEnabled: true
                } as DevExpress.ui.dxSelectBoxOptions,
                helpText:
                  'You may choose from the list of presets or create a custom format. (ex. Using "$#,##0.##" will cause "123456.78" to look like "$123,456.78")'
              },
              {
                dataField: "Alignment",
                editorType: "dxSelectBox"
                // editorOptions: <DevExpress.ui.dxSelectBoxOptions>{
                //    //items: [
                //    //    'left',
                //    //    'center',
                //    //    'right'
                //    //],
                // }
              },
              {
                dataField: "Width",
                helpText:
                  "Keeping this value as auto will cause columns whose values are all shorter than the header to hide the header"
              },
              {
                dataField: "Visible",
                editorType: "dxCheckBox"
              }
            ]
          }
        },
        selection: {
          mode: "multiple"
        },
        paging: {
          enabled: false
        },
        height: "50%",
        columnAutoWidth: true,
        sorting: {
          mode: "none"
        },
        showBorders: true,
        onRowUpdated: e => {
          e.key.Hidden = true;

          TableTile.UpdateColumnSettings([e.key]);

          TableTile.ColumnGrid.clearSelection();

          TableTile.ColumnGrid.getDataSource().reload();
        },
        onRowPrepared: e => {
          if (e.rowType !== "data") {
            return;
          }

          e.rowElement.addClass("table-draggable-row").data("keyValue", e.key);
        },
        onContentReady: e => {
          TableTile.FieldsRowDrag(e.element);
        }
      } as DevExpress.ui.dxDataGridOptions)
      .dxDataGrid("instance");

    const element: JQuery = $("<div />")
      .height("100%")
      .append(form.element(), "<hr />", TableTile.ColumnGrid.element());

    const dialog = new Dialog(
      {
        title: "Configure Columns",
        closable: true,
        size: "size-large",
        body: element,
        onHidden: e => {
          if (
            JSON.stringify(TableTile.Columns) !== JSON.stringify(origColumns)
          ) {
            // We don't need to update a real field, we just have to trigger the form's onFieldDataChanged event.
            TableTile.Form.updateData("DummyField", moment().toDate());
          }
        }
      },
      null,
      false,
      false
    );

    dialog.open();
  }

  private UpdateColumnSettings(UpdatedCols: ITableColumn[]) {
    const TableTile = this;

    for (let i = 0, length = UpdatedCols.length; i < length; i++) {
      for (let j = 0, length2 = TableTile.Columns.length; j < length2; j++) {
        if (TableTile.Columns[j].FieldName === UpdatedCols[i].FieldName) {
          TableTile.Columns[j] = UpdatedCols[i];
        }
      }
    }
  }

  private SetUpdateColumns(UpdatedCols: Array<Partial<IRequestedField>>) {
    const TableTile = this;

    const ColsNeedAdded: Array<Partial<IRequestedField>> = [];
    const ColsNeedRemoved: string[] = [];

    for (let i = 0, length = TableTile.Columns.length; i < length; i++) {
      if (
        UpdatedCols.map(a => a.FieldName).indexOf(
          TableTile.Columns[i].FieldName
        ) === -1
      ) {
        ColsNeedRemoved.push(TableTile.Columns[i].FieldName);
      }
    }

    TableTile.RemoveColumns(ColsNeedRemoved);

    const TargetFieldNames = TableTile.Columns.map(a => a.FieldName);

    for (let i = 0, length = UpdatedCols.length; i < length; i++) {
      if (TargetFieldNames.indexOf(UpdatedCols[i].FieldName) === -1) {
        ColsNeedAdded.push(UpdatedCols[i]);
      }
    }

    TableTile.AddColumns(ColsNeedAdded);
  }

  private RemoveColumns(FieldNames: string[]) {
    const TableTile = this;

    for (let i = 0, length = FieldNames.length; i < length; i++) {
      TableTile.Columns.splice(
        TableTile.Columns.map(a => {
          return a.FieldName;
        }).indexOf(FieldNames[i]),
        1
      );
    }

    TableTile.FixIndeces();
  }

  private FixIndeces() {
    const TableTile = this;

    TableTile.Columns.sort((a, b) => {
      return a.Index - b.Index;
    });

    for (let i = 0, length = TableTile.Columns.length; i < length; i++) {
      TableTile.Columns[i].Index = i;
    }
  }

  private AddColumns(Columns: Array<Partial<IRequestedField>>) {
    const TableTile = this;

    for (let i = 0, length = Columns.length; i < length; i++) {
      TableTile.Columns.push({
        Alignment: "left",
        Caption: Columns[i].FieldName,
        FieldName: Columns[i].FieldName,
        FormatString: "",
        DataType: Columns[i].DataType,
        Visible: true,
        Width: "auto",
        Index: TableTile.Columns.length
      });
    }
  }

  private FieldsRowDrag(gridElement: JQuery) {
    const TableTile = this;

    gridElement.find(".table-draggable-row").draggable({
      helper: "clone",
      start: (event, ui) => {
        const origRow = $(event.target);
        const clonedRow = ui.helper;
        const origRowCells = origRow.children();
        const clonedRowCells = clonedRow.children();

        $.each(origRowCells, (k, v) => {
          $(clonedRowCells.get(k)).width($(origRowCells.get(k)).width());
        });

        clonedRow.addClass("drag-helper").width(clonedRow.width());
      },
      axis: "y"
    } as JQueryUI.Draggable);

    gridElement.find(".table-draggable-row").droppable({
      drop: (event, ui) => {
        if (event.target) {
          const draggingRowKey = ui.draggable.data("keyValue");
          const targetRowKey = $(event.target).data("keyValue");
          let draggingIndex = null;
          let targetIndex = null;

          TableTile.Columns.filter(item => {
            if (draggingRowKey === item) {
              draggingIndex = item.Index;
            }
          });

          TableTile.Columns.filter(item => {
            if (targetRowKey === item) {
              targetIndex = item.Index;
            }
          });

          const draggingDirection = targetIndex < draggingIndex ? 1 : -1;
          let dataItems = null;

          dataItems = TableTile.Columns;

          for (let dataIndex = 0; dataIndex < dataItems.length; dataIndex++) {
            if (
              dataItems[dataIndex].Index >
              Math.min(targetIndex, draggingIndex) &&
              dataItems[dataIndex].Index < Math.max(targetIndex, draggingIndex)
            ) {
              dataItems[dataIndex].Index += draggingDirection;
            }
          }

          // TableTile.FieldsGridDataStore.update(draggingRowKey, { Index: targetIndex });
          TableTile.Columns.filter(item => {
            if (draggingRowKey === item) {
              item.Index = targetIndex;
            }
          });

          // TableTile.FieldsGridDataStore.update(targetRowKey, { Index: targetIndex + draggingDirection });
          TableTile.Columns.filter(item => {
            if (targetRowKey === item) {
              item.Index = targetIndex + draggingDirection;
            }
          });

          gridElement.dxDataGrid("instance").refresh();
        }
      }
    } as JQueryUI.Droppable);
  }
}

TileFactory.RegisterTileType(
  "AdvancedReportingTable",
  "A tile that can be used to display a table.",
  "fa fa-table",
  ReportingTileSettings,
  Tile_Table
);
