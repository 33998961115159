import { Notification } from '../../dialogs/notification';
import 'devextreme/viz/circular_gauge';
import 'devextreme/viz/linear_gauge';
import 'devextreme/ui/color_box';
import themes from 'devextreme/ui/themes';
import { WebEvents } from '../../../infrastructure/events/ui_events';
import {
  IGaugeRange,
  IGaugeSettings
} from '../../../interfaces/advancedreporting/interfaces';
import { TileFactory } from '../../dashboards/tilefactory';
import { TileSettingsBase } from '../../dashboards/tilesettingsbase';
import { ReportingTileBase, ReportingTileSettings } from './tile_reportingbase';
import { eventNameSpace, EventTypes } from '../../../enums/webevents/enums';

export class Tile_Gauge extends ReportingTileBase {
  protected Control:
    | DevExpress.viz.dxCircularGauge
    | DevExpress.viz.dxLinearGauge;
  protected Options: DevExpress.viz.BaseGaugeOptions;
  protected Type: string;

  protected RangeNum: number = 0;
  protected RangeNumsUsed: number[] = [];

  constructor(
    tileInstanceID: string,
    settings: Partial<ReportingTileSettings>,
    settingsClassRef: typeof TileSettingsBase = ReportingTileSettings,
    PreviewMode: boolean = false,
    ShowAdvancedSettings: boolean = false
  ) {
    super(
      tileInstanceID,
      settings,
      settingsClassRef,
      PreviewMode,
      ShowAdvancedSettings
    );

    const GaugeBaseTile = this;

    GaugeBaseTile.AddListeners();
  }

  public GetAllSettingsFormItems(form?: DevExpress.ui.dxForm) {
    const GaugeBaseTile = this;
    const d: JQueryDeferred<any> = $.Deferred();

    if (form) {
      GaugeBaseTile.Form = form;
    }

    GaugeBaseTile.FormFields = [
      {
        caption: 'Gauge Base Values',
        itemType: 'group',
        items: [
          {
            dataField: 'Value',
            editorType: 'dxTextBox',
            editorOptions: {
              onFocusIn: e => {
                GaugeBaseTile.GetBookmarksPopup(
                  e.element,
                  GaugeBaseTile.DatasourceFields.map(a => a.FieldName)
                );
              }
            } as DevExpress.ui.dxTextBoxOptions,
            isRequired: true,
            helpText:
              'Sets the position of the needle. This field allows currently selected datasource fields and arithmetic'
          },
          {
            dataField: 'StartValue',
            editorType: 'dxTextBox',
            editorOptions: {
              onFocusIn: e => {
                GaugeBaseTile.GetBookmarksPopup(
                  e.element,
                  GaugeBaseTile.DatasourceFields.map(a => a.FieldName)
                );
              }
            } as DevExpress.ui.dxTextBoxOptions,
            isRequired: true,
            helpText:
              'Sets the first available value of the gauge. This field allows currently selected datasource fields and arithmetic'
          },
          {
            dataField: 'EndValue',
            editorType: 'dxTextBox',
            editorOptions: {
              onFocusIn: e => {
                GaugeBaseTile.GetBookmarksPopup(
                  e.element,
                  GaugeBaseTile.DatasourceFields.map(a => a.FieldName)
                );
              }
            } as DevExpress.ui.dxTextBoxOptions,
            isRequired: true,
            helpText:
              'Sets the last available value of the gauge. This field allows currently selected datasource fields and arithmetic'
          },
          {
            dataField: 'AllowDecimals',
            editorType: 'dxCheckBox',
            helpText: 'Allows the steps of the gauge to include decimal values'
          },
          {
            dataField: 'Type',
            label: { text: 'Gauge Type' },
            editorType: 'dxSelectBox',
            editorOptions: {
              items: [
                { display: 'Circular', value: 'circular' },
                { display: 'Linear', value: 'linear' }
              ],
              displayExpr: 'display',
              valueExpr: 'value'
            } as DevExpress.ui.dxSelectBoxOptions,
            helpText: 'Changes the kind of gauge that is rendered'
          }
        ]
      },
      {
        caption: 'CSS Settings',
        visible: GaugeBaseTile.ShowAdvancedSettings,
        itemType: 'group',
        items: [
          {
            dataField: 'CssClass',
            label: { text: 'CSS Class' },
            editorType: 'dxTextBox',
            isRequired: false,
            helpText:
              'Adds the given text as a class on the root element of the control'
          },
          {
            dataField: 'CssID',
            label: { text: 'CSS ID' },
            editorType: 'dxTextBox',
            isRequired: false,
            helpText:
              'Adds the given text as an ID on the root element of the control'
          }
        ]
      },
      {
        caption: 'Range Formatting',
        visible: GaugeBaseTile.ShowAdvancedSettings,
        itemType: 'group',
        colSpan: 2,
        colCount: 2,
        items: [],
        helpText:
          'NOTE: In the event two ranges overlap, the last range to resolve will receive the overlapped area. Rules are resolved from left to right and top to bottom.'
      }
    ];

    GaugeBaseTile.GetTileObject().done(tile => {
      if ((tile.Settings as IGaugeSettings).Ranges.length > 0) {
        GaugeBaseTile.RangeNum = 0;
        GaugeBaseTile.RangeNumsUsed = [];

        $.each((tile.Settings as IGaugeSettings).Ranges, (k, v) => {
          GaugeBaseTile.AddRange(false);
        });

        GaugeBaseTile.UpdateFormFields();
      }

      d.resolve(GaugeBaseTile.FormFields);
    });

    return d.promise();
  }

  public GetSettingsData(): IGaugeSettings {
    const GaugeBaseTile = this;

    const formData = GaugeBaseTile.Form.option('formData');

    const settings: IGaugeSettings = {
      AllowDecimals: formData.AllowDecimals,
      CssClass: formData.CssClass,
      CssID: formData.CssID,
      EndValue: formData.EndValue,
      Ranges: GaugeBaseTile.GetRangeSettings(),
      StartValue: formData.StartValue,
      Subtitle: formData.Subtitle,
      Value: formData.Value,
      Type: formData.Type
    };

    return settings;
  }

  public SetSettingsData(settings: IGaugeSettings) {
    const GaugeBaseTile = this;

    const formData = settings;

    $.each(settings.Ranges, (k, v) => {
      formData[`Color${k + 1}`] = v.Color;
      formData[`StartValue${k + 1}`] = v.StartValue;
      formData[`EndValue${k + 1}`] = v.EndValue;
    });

    GaugeBaseTile.Form.option('formData', formData);
  }

  protected CreateAndAddControl(element) {
    const GaugeTile = this;

    if (GaugeTile.Type === 'circular') {
      GaugeTile.Control = $('<div />')
        .dxCircularGauge(GaugeTile.Options)
        .dxCircularGauge('instance');
    } else if (GaugeTile.Type === 'linear') {
      GaugeTile.Control = $('<div />')
        .dxLinearGauge(GaugeTile.Options)
        .dxLinearGauge('instance');
    }

    const div: JQuery = $('<div />')
      .css({
        display: 'flex',
        'justify-content': 'center'
      })
      .append(GaugeTile.Control.element());

    element.append(div);
  }

  protected ConvertSettingsToControlOptions(
    settings: IGaugeSettings
  ): JQueryPromise<DevExpress.viz.BaseGaugeOptions> {
    const GaugeBaseTile = this;
    const d: JQueryDeferred<DevExpress.viz.BaseGaugeOptions> = $.Deferred();

    GaugeBaseTile.GetTileData().done(tileData => {
      let options: DevExpress.viz.BaseGaugeOptions;

      let dataRow;
      if (tileData) {
        dataRow = tileData[0];
      }

      options = {
        export: {
          formats: ['JPEG', 'PDF', 'PNG', 'SVG']
        },
        rangeContainer: {
          backgroundColor: '#000000',
          ranges: GaugeBaseTile.MapRanges(settings.Ranges, dataRow)
        },
        scale: {
          allowDecimals: settings.AllowDecimals,
          endValue:
            typeof settings.EndValue === 'string'
              ? GaugeBaseTile.ConvertValues(settings.EndValue, dataRow)
              : settings.EndValue,
          startValue:
            typeof settings.StartValue === 'string'
              ? GaugeBaseTile.ConvertValues(settings.StartValue, dataRow)
              : settings.StartValue
        },
        value:
          typeof settings.Value === 'string'
            ? GaugeBaseTile.ConvertValues(settings.Value, dataRow)
            : settings.Value,
        theme: themes.current() as any,
        elementAttr: {
          id: settings.CssID !== '' ? settings.CssID : undefined,
          class: settings.CssClass !== '' ? settings.CssClass : undefined
        }
      };

      GaugeBaseTile.Type = settings.Type;

      d.resolve(options);
    });

    return d.promise();
  }

  private MapRanges(ranges: IGaugeRange[], dataRow?) {
    const GaugeBaseTile = this;

    const gaugeRanges = [];

    for (let i = 0, length = ranges.length; i < length; i++) {
      gaugeRanges.push({
        color: ranges[i].Color,
        endValue:
          typeof ranges[i].EndValue === 'string'
            ? GaugeBaseTile.ConvertValues(ranges[i].EndValue, dataRow)
            : ranges[i].EndValue,
        startValue:
          typeof ranges[i].StartValue === 'string'
            ? GaugeBaseTile.ConvertValues(ranges[i].StartValue, dataRow)
            : ranges[i].StartValue
      });
    }

    return gaugeRanges;
  }

  private ConvertValues(valString: string, dataRow) {
    const GaugeBaseTile = this;
    const origValString = valString;
    let count = 0; // Emergency Exit

    while (valString.indexOf('{{') !== -1 || count > 50) {
      const colStart = valString.indexOf('{{');
      const colEnd = valString.indexOf('}}') + 2;

      const currentVal =
        dataRow[valString.substring(colStart, colEnd).replace(/[\{\}]/g, '')];

      valString = valString.replace(
        valString.substring(colStart, colEnd),
        currentVal
      );

      count++;
    }

    if (count > 50) {
      new Notification({
        message: `Error converting ${origValString} to numeric value.`,
        type: 'error'
      });
    }

    const value = eval(valString);

    return value;
  }

  private AddRange(UpdateForm: boolean = true) {
    const GaugeBaseTile = this;

    GaugeBaseTile.RangeNum++;

    const index = GaugeBaseTile.RangeNum;

    GaugeBaseTile.RangeNumsUsed.push(index);

    (GaugeBaseTile.FormFields[2] as DevExpress.ui.dxFormGroupItem).items.push({
      caption: `Custom Range`,
      name: `Range - ${index}`,
      itemType: 'group',
      items: [
        {
          dataField: `Color${index}`,
          label: {
            text: 'Color'
          },
          editorType: 'dxColorBox',
          helpText: 'Sets the color of this range'
        },
        {
          dataField: `StartValue${index}`,
          label: {
            text: 'Start Value'
          },
          editorType: 'dxTextBox',
          editorOptions: {
            onFocusIn: e => {
              GaugeBaseTile.GetBookmarksPopup(
                e.element,
                GaugeBaseTile.DatasourceFields.map(a => a.FieldName)
              );
            }
          } as DevExpress.ui.dxTextBoxOptions,
          isRequired: true,
          helpText:
            'Sets the first available value of this range. This field allows currently selected datasource fields and arithmetic'
        },
        {
          dataField: `EndValue${index}`,
          label: {
            text: 'End Value'
          },
          editorType: 'dxTextBox',
          editorOptions: {
            onFocusIn: e => {
              GaugeBaseTile.GetBookmarksPopup(
                e.element,
                GaugeBaseTile.DatasourceFields.map(a => a.FieldName)
              );
            }
          } as DevExpress.ui.dxTextBoxOptions,
          isRequired: true,
          helpText:
            'Sets the last available value of this range. This field allows currently selected datasource fields and arithmetic'
        }
      ] as Array<
        DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormButtonItem
      >
    });

    if (UpdateForm) {
      GaugeBaseTile.UpdateFormFields();
    }
  }

  private RemoveRange(rangeNum: number) {
    const GaugeBaseTile = this;

    let indexToRemove;

    $.each(
      (GaugeBaseTile.FormFields[2] as DevExpress.ui.dxFormGroupItem).items,
      (k, v) => {
        if (v.itemType === 'group' && v.name === `Range - ${rangeNum}`) {
          indexToRemove = k;
        }
      }
    );

    (GaugeBaseTile.FormFields[2] as DevExpress.ui.dxFormGroupItem).items.splice(
      indexToRemove,
      1
    );

    GaugeBaseTile.RangeNumsUsed.splice(
      GaugeBaseTile.RangeNumsUsed.indexOf(rangeNum),
      1
    );

    GaugeBaseTile.UpdateFormFields();
  }

  private GetRangeSettings() {
    const GaugeBaseTile = this;

    const data = GaugeBaseTile.Form.option('formData');

    const compiledRangeValue = [];

    $.each(GaugeBaseTile.RangeNumsUsed, (k, v) => {
      compiledRangeValue.push({
        Color: data[`Color${v}`],
        StartValue: data[`StartValue${v}`],
        EndValue: data[`EndValue${v}`]
      });
    });

    return compiledRangeValue;
  }

  private AddListeners() {
    const GaugeBaseTile = this;

    WebEvents.Event.AddHandler(
      EventTypes.DashboardEventTypes.FormReady,
      eventNameSpace.notify,
      GaugeBaseTile.Element,
      GaugeBaseTile.AddRangeFormattingButtons.bind(GaugeBaseTile)
    );
  }

  private AddRangeFormattingButtons(e, data) {
    const GaugeBaseTile = this;

    if (
      GaugeBaseTile.ShowAdvancedSettings &&
      $('.dx-form-group-caption:contains(Range Formatting)').find('.dx-button')
        .length === 0
    ) {
      const AddRangeButton: DevExpress.ui.dxButton = $('<div />')
        .css({
          'margin-left': '10px',
          'border-radius': '50px'
        })
        .dxButton({
          icon: 'fa fa-plus-circle',
          type: 'success',
          onClick: e => {
            GaugeBaseTile.AddRange();
          }
        } as DevExpress.ui.dxButtonOptions)
        .dxButton('instance');

      AddRangeButton.element()
        .on('mouseenter', () => {
          AddRangeButton.option('text', 'Add New Range');
        })
        .on('mouseleave', () => {
          AddRangeButton.option('text', '');
        });

      $('.dx-form-group-caption:contains(Range Formatting)').append(
        AddRangeButton.element()
      );

      for (
        let i = 0, length = GaugeBaseTile.RangeNumsUsed.length;
        i < length;
        i++
      ) {
        const removeIndex = GaugeBaseTile.RangeNumsUsed[i];

        const RemoveRangeButton: DevExpress.ui.dxButton = $('<div />')
          .css({
            'margin-left': '10px',
            'border-radius': '50px',
          })
          .dxButton({
            icon: 'fa fa-minus-circle',
            type: 'danger',
            onClick: e => {
              GaugeBaseTile.RemoveRange(removeIndex);
            }
          } as DevExpress.ui.dxButtonOptions)
          .dxButton('instance');

        RemoveRangeButton.element()
          .on('mouseenter', () => {
            RemoveRangeButton.option('text', 'Remove This Range');
          })
          .on('mouseleave', () => {
            RemoveRangeButton.option('text', '');
          });

        $('.dx-form-group-caption:contains(Range):not(:first)')
          .eq(i)
          .append(RemoveRangeButton.element());
      }
    }
  }
}

TileFactory.RegisterTileType(
  'AdvancedReportingGauge',
  'A tile that can be used to display a gauge.',
  'fa fa-tachometer',
  ReportingTileSettings,
  Tile_Gauge
);
