import { IHtmlSettings } from "../../../interfaces/advancedreporting/interfaces";
import { TileScopeType } from "../../dashboards/tilebase";
import { TileFactory } from "../../dashboards/tilefactory";
import { TileSettingsBase } from "../../dashboards/tilesettingsbase";
import { Tile_Repeater } from "./tile_code";
import { ReportingTileSettings } from "./tile_reportingbase";
export class Tile_Header extends Tile_Repeater {
    protected _ScopeSupportLevel: TileScopeType = TileScopeType.ScopeNotSupported;
    constructor(tileID: string, settings: Partial<ReportingTileSettings>, settingsClassRef: typeof TileSettingsBase = ReportingTileSettings, PreviewMode: boolean = false, ShowAdvancedSettings: boolean = false) {
        super(tileID, settings, settingsClassRef, PreviewMode, ShowAdvancedSettings);
    }
    public GetAllSettingsFormItems(form?: DevExpress.ui.dxForm) {
        const HeaderTile = this;
        const d: JQueryDeferred<any> = $.Deferred();
        if (form) {
            HeaderTile.Form = form;
        }
        const options: DevExpress.ui.dxFormSimpleItem[] = [
            {
                template: (itemData, itemElement) => {
                    const description = $("<p></p>").text("Template editors support HTML and JavaScript");
                    itemElement.append(description);
                },
                colSpan: 2,
            }, {
                dataField: "NoDataTemplate",
                label: { text: "Header Section" },
                template: HeaderTile.SetFormItemTemplate.bind(HeaderTile, "NoData"),
                colSpan: 2,
                helpText: "Code written here will be run once and used as the control",
            },
        ];
        return d.promise(d.resolve(options));
    }
    public GetSettingsData(): IHtmlSettings {
        const HtmlTile = this;
        const formData = HtmlTile.Form.option("formData");
        const settings: IHtmlSettings = {
            HeaderTemplate: "",
            ItemTemplate: "",
            FooterTemplate: "",
            NoDataTemplate: HtmlTile.NoDataEditor.EditorInstance.getValue(),
            CssClass: "",
            CssID: "",
        };
        return settings;
    }
    protected ConvertSettingsToControlOptions(settings: IHtmlSettings) {
        const HeaderTile = this;
        const d: JQueryDeferred<any> = $.Deferred();
        return d.promise(d.resolve(settings.NoDataTemplate));
    }
}
TileFactory.RegisterTileType("AdvancedReportingHeader", "A tile that can be used to display a header section on a report.", "fa fa-code", ReportingTileSettings, Tile_Header);
