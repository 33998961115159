import { TileBase } from "./tilebase";
import { TileSettingsBase } from "./tilesettingsbase";
export const gTileTypes:TileInfo[] =[];
    export class TileInfo {
        TileType: string = "";
        Info: string = "";
        Icon: string = "";
        SettingsClassRef: typeof TileSettingsBase;
        ClassRef: typeof TileBase;
    }

    export class TileFactory{
        static TileTypes : TileInfo[] = [];

        static CreateTile(tileType: string, tileInstanceID: string, tileSettings: any): TileBase {
            let newTile: TileBase;
            let i: number = 0;

            for (i = 0; i < TileFactory.TileTypes.length; i++) {
                let tileOptionInfo: TileInfo = TileFactory.TileTypes[i];
                if (tileType === tileOptionInfo.TileType) { 
                    newTile = new tileOptionInfo.ClassRef(tileInstanceID, tileSettings, tileOptionInfo.SettingsClassRef);
                }
            }

            return (newTile);
        }

        static RegisterTileType(tileType: string, info: string, icon: string, settingsClassRef: typeof TileSettingsBase, classRef: typeof TileBase) : boolean {
            let success: boolean = false;

            let newTile = new TileInfo();
            newTile.TileType = tileType;
            newTile.Info = info;
            newTile.Icon = icon;
            newTile.SettingsClassRef = settingsClassRef;
            newTile.ClassRef = classRef;

            TileFactory.TileTypes.push(newTile);

            return success;
        }

        static ListTileTypes() {
            $.each(TileFactory.TileTypes, function (index: number, value: TileInfo) {
                console.log(`${value.TileType} - ${value.Info}`);
            });
        }
    }
