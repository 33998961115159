import "devextreme/ui/pivot_grid_field_chooser";
import "devextreme/viz/chart";
import themes from "devextreme/ui/themes";
import {
  IBaseChartSettings,
  IRequestedField
} from "../../../interfaces/advancedreporting/interfaces";
import { TileSettingsBase } from "../../dashboards/tilesettingsbase";
import { ReportingTileBase, ReportingTileSettings } from "./tile_reportingbase";
import { CloneIT } from "../../../util/allutils";
import dxDataSource from "devextreme/data/data_source";
import dxCustomStore from "devextreme/data/custom_store";
import dxPivotGridDataSource from "devextreme/ui/pivot_grid/data_source";

export abstract class Tile_ChartBase extends ReportingTileBase {
  public FormNeedsValidated = false;

  protected Control: DevExpress.viz.dxChart | DevExpress.viz.dxPieChart;
  protected Options:
    | DevExpress.viz.charts.dxChartOptions
    | DevExpress.viz.charts.dxPieChartOptions;
  protected ChartSettings: IBaseChartSettings;

  protected FieldChooser: DevExpress.ui.dxPivotGridFieldChooser;
  protected FieldChooserDataSource: DevExpress.data.PivotGridDataSource;
  protected FieldChooserEventHookup: DevExpress.ui.dxPivotGrid;

  protected abstract Type;

  private PivotedFields: string[] = [];
  private ChangingFields: boolean = false;

  constructor(
    tileInstanceID: string,
    settings: Partial<ReportingTileSettings>,
    settingsClassRef: typeof TileSettingsBase = ReportingTileSettings,
    PreviewMode: boolean = false,
    ShowAdvancedSettings: boolean
  ) {
    super(
      tileInstanceID,
      settings,
      settingsClassRef,
      PreviewMode,
      ShowAdvancedSettings
    );
  }

  public GetAllSettingsFormItems(form?: DevExpress.ui.dxForm): JQueryPromise<any> {
    const ChartTile = this;
    const d: JQueryDeferred<any> = $.Deferred();

    if (form) {
      ChartTile.Form = form;
    }

    const options:
      | DevExpress.ui.dxFormSimpleItem[]
      | DevExpress.ui.dxFormGroupItem[] = [
        {
          colSpan: 2,
          template: (itemData, itemElement) => {
            ChartTile.FieldChooser = $("<div />")
              .dxPivotGridFieldChooser({
                dataSource: ChartTile.FieldChooserDataSource,
                texts: {
                  allFields: "Available Fields",
                  rowFields: "Arguments",
                  columnFields: "Series",
                  dataFields: "Values",
                  filterFields: ""
                },
                onContentReady: e => {
                  e.element
                    .find('[group="filter"]')
                    .parent()
                    .remove();

                  if (ChartTile.Type === "pie") {
                    e.element
                      .find('[group="column"]')
                      .parent()
                      .remove();
                  }
                },
                layout: 1,
                allowSearch: true
              } as DevExpress.ui.dxPivotGridFieldChooserOptions)
              .dxPivotGridFieldChooser("instance");

            itemElement.append(ChartTile.FieldChooser.element());
          },
          helpText:
            ChartTile.Type === "pie" || ChartTile.Type === "donut"
              ? "Drag fields between the above areas to build the structure of the chart. The Arguments and Values areas should only have one field in each."
              : "Drag fields between the above areas to build the structure of the chart. Having more than one field in the Arguments or Series areas will cause that area to use the literal field names as the arguments or series and will not use anything given in the Values area."
        },
        {
          caption: "Legend Settings",
          itemType: "group",
          items: [
            {
              dataField: "ShowLegend",
              editorType: "dxCheckBox",
              helpText: "Affects the visibility of the chart's legend"
            },
            {
              dataField: "LegendLocation",
              editorType: "dxSelectBox",
              editorOptions: {
                items: [
                  {
                    display: "Top",
                    value: "top"
                  },
                  {
                    display: "Bottom",
                    value: "bottom"
                  },
                  {
                    display: "Left",
                    value: "left"
                  },
                  {
                    display: "Right",
                    value: "right"
                  }
                ],
                displayExpr: "display",
                valueExpr: "value"
              } as DevExpress.ui.dxSelectBoxOptions,
              helpText: "Affects the position of the chart's legend"
            }
          ]
        } as DevExpress.ui.dxFormGroupItem,
        {
          caption: "CSS Settings",
          visible: ChartTile.ShowAdvancedSettings,
          itemType: "group",
          items: [
            {
              dataField: "CssClass",
              label: { text: "CSS Class" },
              editorType: "dxTextBox",
              isRequired: false,
              helpText:
                "Adds the given text as a class on the root element of the control"
            },
            {
              dataField: "CssID",
              label: { text: "CSS ID" },
              editorType: "dxTextBox",
              isRequired: false,
              helpText:
                "Adds the given text as an ID on the root element of the control"
            }
          ]
        }
      ];

    return d.promise(d.resolve(options));
  }

  public GetSettingsData(): IBaseChartSettings {
    const ChartTile = this;

    const formData: IBaseChartSettings = ChartTile.Form.option("formData");

    const fields = ChartTile.CompileFields();

    const settings: IBaseChartSettings = {
      ValueField: fields.Value,
      NameField: fields.Names,
      ArgumentField: fields.Arguments,
      ArgumentType: ChartTile.FindArgumentType(fields.Arguments),
      ShowLegend: formData.ShowLegend,
      LegendLocation: formData.LegendLocation,
      Series: [],
      Type: formData.Type,
      CssClass: formData.CssClass,
      CssID: formData.CssID
    };

    return settings;
  }

  public SetSettingsData(settings: IBaseChartSettings, UpdateForm = true) {
    const ChartTile = this;

    if (settings.LegendLocation === undefined) {
      settings.LegendLocation = "right";
    }

    if (settings.NameField !== undefined) {
      settings.NameField = settings.NameField;
      settings.ValueField = settings.ValueField;
      settings.ArgumentField = settings.ArgumentField;
    }

    ChartTile.ChartSettings = settings;

    if (ChartTile.FieldChooserDataSource) {
      ChartTile.FieldChooserDataSource.fields(ChartTile.GetFieldChooserItems());
    } else {
      ChartTile.FieldChooserDataSource = new dxPivotGridDataSource({
        fields: ChartTile.GetFieldChooserItems(),
        retrieveFields: false,
        onChanged: () => {
          if (!ChartTile.ChangingFields) {
            ChartTile.Form.updateData(settings);
          }
        }
      });
    }

    if (UpdateForm) {
      ChartTile.Form.option("formData", settings);
    }

    return settings;
  }

  public SetDatasourceFields(datasourceFields: IRequestedField[]) {
    const ChartTile = this;

    const d: JQueryDeferred<any[]> = $.Deferred();

    const origDatasourceFields = CloneIT(ChartTile.DatasourceFields);

    super.SetDatasourceFields(datasourceFields).done(tileData => {
      if (
        ChartTile.FieldChooserDataSource &&
        JSON.stringify(origDatasourceFields) !==
        JSON.stringify(ChartTile.DatasourceFields)
      ) {
        ChartTile.ChangingFields = true;

        ChartTile.FieldChooserDataSource.fields(
          ChartTile.GetFieldChooserItems()
        );
        ChartTile.FieldChooserDataSource.reload().done(() => {
          ChartTile.ChangingFields = false;
        });
      }

      d.resolve(tileData);
    });

    return d.promise();
  }

  protected CreateAndAddControl(element) {
    const ChartTile = this;

    ChartTile.Options.onDone = e => {
      if (element.length && element.width()) {
        e.component.option("size.width", element.width() - 20);
      }
    };

    ChartTile.Control = $("<div />")
      .dxChart(ChartTile.Options as DevExpress.viz.charts.dxChartOptions)
      .dxChart("instance");
    ChartTile.SetDataSource();

    element.append(ChartTile.Control.element());
  }

  protected ConvertSettingsToControlOptions(settings: IBaseChartSettings) {
    const ChartTile = this;
    const d: JQueryDeferred<DevExpress.viz.dxChartOptions> = $.Deferred();

    if (settings.IncomingDataLayout === "PivotedForArguments") {
      ChartTile.PivotedFields = settings.NameField;
    } else if (settings.IncomingDataLayout === "PivotedForSeries") {
      ChartTile.PivotedFields = settings.ArgumentField;
    } else {
      ChartTile.PivotedFields = [];
    }

    const chartOptions: DevExpress.viz.dxChartOptions = {
      argumentAxis: {
        argumentType: settings.ArgumentType,
        endOnTick: false,
        label: {
          alignment: "right",
          overlappingBehavior: "rotate",
          rotationAngle: -45
        }
      },
      valueAxis: {
        endOnTick: true,
        grid: {
          visible: true
        },
        label: {
          alignment: "right",
          overlappingBehavior: "rotate",
          rotationAngle: -45
        }
      },
      commonSeriesSettings: {
        argumentField:
          settings.IncomingDataLayout === "Unpivoted"
            ? settings.ArgumentField[0]
            : settings.IncomingDataLayout === "PivotedForSeries"
              ? "arg"
              : settings.ArgumentField[0],
        valueField:
          settings.IncomingDataLayout !== "Unpivoted"
            ? "val"
            : settings.ValueField,
        type: settings.Type as any
      },
      legend: ChartTile.MapLegend(settings.ShowLegend, settings.LegendLocation),
      theme: themes.current() as any,
      elementAttr: {
        id: settings.CssID !== "" ? settings.CssID : undefined,
        class: settings.CssClass !== "" ? settings.CssClass : undefined
      }
    };

    if (settings.NameField.length === 0) {
      chartOptions.series = [
        {
          argumentField: settings.ArgumentField[0],
          valueField: settings.ValueField,
          name: "Series",
          type: settings.Type as any
        }
      ];
    } else {
      chartOptions.seriesTemplate = {
        nameField:
          settings.IncomingDataLayout === "Unpivoted"
            ? settings.NameField[0]
            : settings.IncomingDataLayout === "PivotedForArguments"
              ? "arg"
              : settings.NameField[0]
      };
    }

    return d.promise(d.resolve(chartOptions));
  }

  protected MapLegend(visible: boolean, alignment: string) {
    if (visible) {
      let vertical;
      let horizontal;

      switch (alignment) {
        case "top":
          vertical = "top";
          horizontal = "center";
          break;

        case "bottom":
          vertical = "bottom";
          horizontal = "center";
          break;

        case "left":
          vertical = "top";
          horizontal = "left";
          break;

        case "right":
          vertical = "top";
          horizontal = "right";
          break;
      }

      return {
        visible,
        horizontalAlignment: horizontal,
        verticalAlignment: vertical
      };
    } else {
      return {
        visible: false
      };
    }
  }

  protected SetDataSource() {
    const ChartTile = this;

    const temp = ChartTile.PivotedFields;

    if (ChartTile.PivotedFields.length === 0) {
      super.SetDataSource();
    } else {
      ChartTile.Control.option(
        "dataSource",
        new dxDataSource(
          new dxCustomStore({
            load: () => {
              const d = $.Deferred();

              ChartTile.GetTileData().done(data => {
                d.resolve(ChartTile.PivotData(data, temp));
              });

              return d.promise();
            }
          })
        )
      );
    }
  }

  protected CompileFields() {
    const ChartTile = this;

    let fieldData: DevExpress.data.PivotGridDataSource;
    if (ChartTile.FieldChooser.getDataSource()) {
      fieldData = ChartTile.FieldChooser.getDataSource();
    }

    const ArgumentFields = fieldData
      ? fieldData.getAreaFields("row", true).map(a => a.dataField)
      : undefined;
    const NameFields = fieldData
      ? fieldData.getAreaFields("column", true).map(a => a.dataField)
      : undefined;
    const ValueField = fieldData
      ? fieldData.getAreaFields("data", false).map(a => a.dataField)[0]
      : undefined;

    return { Arguments: ArgumentFields, Names: NameFields, Value: ValueField };
  }

  protected FindPivotSetting(
    ArgumentFields: string[],
    NameFields: string[]
  ): "Unpivoted" | "PivotedForArguments" | "PivotedForSeries" {
    let PivotSetting: "Unpivoted" | "PivotedForArguments" | "PivotedForSeries" =
      "Unpivoted";

    if (ArgumentFields && NameFields) {
      if (NameFields.length > 1 && ArgumentFields.length === 1) {
        PivotSetting = "PivotedForArguments";
      } else if (ArgumentFields.length > 1 && NameFields.length === 1) {
        PivotSetting = "PivotedForSeries";
      }
    }

    return PivotSetting;
  }

  private PivotData(ds: any[], fieldsToPivot: string[]) {
    const newData: Object[] = [];
    const nonPivotKeys: string[] = [];

    // Get all keys of the object that are not in fieldsToPivot. These will be unaffected and placed in each new 'row' of data.
    Object.keys(ds[0]).forEach(key => {
      if (fieldsToPivot.indexOf(key) === -1) {
        nonPivotKeys.push(key);
      }
    });

    ds.forEach((rowObject: Object) => {
      // For each pivoted field, create a new 'row' with that field pivoted and all fields that are not going to be pivoted.
      Object.keys(rowObject).forEach(key => {
        if (fieldsToPivot.indexOf(key) !== -1) {
          const obj: any = {};

          for (let i = 0, length = nonPivotKeys.length; i < length; i++) {
            obj[nonPivotKeys[i]] = rowObject[nonPivotKeys[i]];
          }

          obj.arg = key;
          obj.val = rowObject[key];

          newData.push(obj);
        }
      });
    });

    return newData;
  }

  private GetFieldChooserItems(): DevExpress.data.PivotGridDataSourceField[] {
    const ChartTile = this;

    const settings = ChartTile.ChartSettings;

    return ChartTile.DatasourceFields.map((a, k) => {
      let setArea;

      if (
        settings.NameField &&
        settings.NameField.indexOf(a.FieldName) !== -1
      ) {
        setArea = "column";
      } else if (
        settings.ValueField &&
        settings.ValueField.indexOf(a.FieldName) !== -1
      ) {
        setArea = "data";
      } else if (
        settings.ArgumentField &&
        settings.ArgumentField.indexOf(a.FieldName) !== -1
      ) {
        setArea = "row";
      }

      return {
        dataField: a.FieldName,
        caption: a.FieldName,
        dataType: a.DataType,
        area: setArea,
        index: k
      } as DevExpress.data.PivotGridDataSourceField;
    });
  }

  private FindArgumentType(ArgumentFields: string[]) {
    const ChartTile = this;

    const ArgumentFieldsWithDataTypes = ChartTile.DatasourceFields.filter(a => {
      return ArgumentFields.indexOf(a.FieldName) !== -1;
    }).map(b => {
      return b.DataType;
    });

    let argumentType: "string" | "numeric" | "datetime";

    if (ArgumentFields.length > 1) {
      argumentType = "string";
    } else {
      if (ArgumentFieldsWithDataTypes.indexOf("string") !== -1) {
        argumentType = "string";
      } else if (ArgumentFieldsWithDataTypes.indexOf("number") !== -1) {
        argumentType = "numeric";
      } else {
        argumentType = "datetime";
      }
    }

    return argumentType;
  }
}
