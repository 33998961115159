import { Dialog } from "../../dialogs/dialog";
import { Notification } from "../../dialogs/notification";
import {
  IReportingRequestObject,
  IRequestedField,
  ITileInfo
} from "../../../interfaces/advancedreporting/interfaces";
import dxDataSource from "devextreme/data/data_source";
import dxCustomStore from "devextreme/data/custom_store";
import * as moment from "moment";
import { TDFRequest } from "../../../services/request";
import { TileBase } from "../../dashboards/tilebase";
import { TileSettingsBase } from "../../dashboards/tilesettingsbase";
import "devextreme/ui/list";
import "devextreme/ui/popover";
import { CurrentUser, LoadUser } from "../../../infrastructure/context";

export class ReportingTileSettings extends TileSettingsBase {
  public ReportingTileID?: string;
}

export abstract class ReportingTileBase extends TileBase {
  private get AdvancedReportingTileSettings(): ReportingTileSettings {
    const reportingTileBase = this;
    return reportingTileBase.Settings as ReportingTileSettings;
  }

  public set RequestObject(obj: IReportingRequestObject) {
    const reportingTileBase = this;

    reportingTileBase._RequestObject = obj;
  }

  public set TileObject(tile: ITileInfo) {
    const reportingTileBase = this;

    reportingTileBase._TileObject = tile;
  }

  private get LastDataGrab(): moment.Moment {
    const reportingTileBase = this;

    if (reportingTileBase._LastDataGrab) {
      return reportingTileBase._LastDataGrab;
    } else {
      return null;
    }
  }

  private set LastDataGrab(currentTime: moment.Moment) {
    const reportingTileBase = this;

    reportingTileBase._LastDataGrab = currentTime;
  }
  public FormNeedsValidated = true;

  protected Element: JQuery = $("#preview-element");
  protected Control;
  protected Options;
  protected Form: DevExpress.ui.dxForm;
  protected DataUrl: string;
  protected UrlStub: string = TDFRequest.ApiPath + "/Reporting/Tile/";
  protected PreviewMode: boolean = false;
  protected FormFields: Array<
    | DevExpress.ui.dxFormSimpleItem
    | DevExpress.ui.dxFormGroupItem
    | DevExpress.ui.dxFormButtonItem
  >;
  protected DatasourceFields: Array<Partial<IRequestedField>> = [];
  protected ShowAdvancedSettings: boolean = false;

  private _RequestObject: IReportingRequestObject;
  private _TileObject: ITileInfo;
  private _TileData: any[];
  private _LastDataGrab: moment.Moment;

  constructor(
    tileInstanceID: string,
    settings: Partial<ReportingTileSettings>,
    settingsClassRef: typeof TileSettingsBase = ReportingTileSettings,
    PreviewMode: boolean = false,
    ShowAdvancedSettings: boolean
  ) {
    super(tileInstanceID, settings, settingsClassRef);

    const ReportingTile = this;

    ReportingTile.PreviewMode = PreviewMode;
    ReportingTile.ShowAdvancedSettings = ShowAdvancedSettings;
  }

  public abstract GetAllSettingsFormItems(
    form?: DevExpress.ui.dxForm
  ): JQueryPromise<any>;
  public abstract GetSettingsData();
  public abstract SetSettingsData(settings);

  public Render(element: JQuery): JQueryPromise<any> {
    const reportingTileBase = this;
    const d: JQueryDeferred<any> = $.Deferred();

    reportingTileBase.Element = element;

    reportingTileBase.GetConfigurationByID().done((tileFound: boolean) => {
      if (tileFound) {
        reportingTileBase.CreateAndAddControl(element);
      } else {
        reportingTileBase.LoadUnknownTile(element);
      }
      d.resolve();
    });

    return d.promise();
  }

  public RenderByObject(
    element: JQuery,
    requestObject: IReportingRequestObject
  ) {
    const reportingTileBase = this;
    const d: JQueryDeferred<void> = $.Deferred();

    reportingTileBase.Element = element;

    reportingTileBase.PreviewMode = true;

    reportingTileBase.TileObject = requestObject.tile as ITileInfo;

    reportingTileBase.RequestObject = requestObject;

    reportingTileBase.GetConfigurationByObject().done(() => {
      reportingTileBase.CreateAndAddControl(element);

      d.resolve();
    });

    return d.promise();
  }

  public GetAllSettingsFormValues() {
    const reportingTileBase = this;
    const d: JQueryDeferred<any> = $.Deferred();

    reportingTileBase.GetRequestObject().done(requestObj => {
      new TDFRequest({
        url: `${reportingTileBase.UrlStub}/${
          reportingTileBase.AdvancedReportingTileSettings.ReportingTileID
          }/ReportingObject`,
        type: "POST",
        data: requestObj
      })
        .MakeRequest()
        .done((response: ITileInfo) => {
          d.resolve(response.Settings);
        });
    });

    return d.promise();
  }

  public SetDatasourceFields(
    datasourceFields: IRequestedField[]
  ): JQueryPromise<any[]> {
    const ReportingTile = this;

    const d: JQueryDeferred<any[]> = $.Deferred();

    datasourceFields.sort((a, b) => {
      if (a.FieldName < b.FieldName) {
        return -1;
      } else if (a.FieldName > b.FieldName) {
        return 1;
      }
      return 0;
    });

    ReportingTile.DatasourceFields.splice(0);

    for (let i = 0, length = datasourceFields.length; i < length; i++) {
      ReportingTile.DatasourceFields.push(datasourceFields[i]);
    }

    ReportingTile.GetTileData(true).done(tileData => {
      if (ReportingTile.Element && ReportingTile.DatasourceFields.length > 0) {
        ReportingTile.GetTileObject().done(tileObj => {
          ReportingTile.GetRequestObject().done(requestObj => {
            requestObj.tile = tileObj;
            ReportingTile.RenderByObject(ReportingTile.Element, requestObj);

            d.resolve(tileData);
          });
        });
      } else {
        d.resolve(tileData);
      }
    });

    return d.promise();
  }

  public GetScriptingDialog() {
    const div: JQuery = $("<div />")
      .attr("id", "reporting-scripting-editor")
      .addClass("reporting-bordered-section")
      .css({
        height: "80%"
      });

    const scriptDialog = new Dialog(
      {
        title: "Customize Scripting",
        closable: true,
        body: div,
        buttons: [
          {
            location: "after",
            toolbar: "bottom",
            widget: "dxButton",
            options: {
              text: "OK",
              type: 'success',
              icon: 'check',
              onClick: () => {
                scriptDialog.close();
              }
            } as DevExpress.ui.dxButtonOptions
          }, {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: {
              text: 'Cancel',
              type: 'danger',
              icon: 'remove',
              onClick: (e) => {
                scriptDialog.close();
              }
            }
          }
        ]
      },
      null,
      false,
      false
    );

    scriptDialog.open().done(() => {
      const time = setInterval(() => {
        if ($("#reporting-scripting-editor").length > 0) {
          clearInterval(time);

          // scriptingEditor.setValue();
        }
      }, 50);
    });

    new Notification({
      message: "Not hooked up!!",
      type: "error"
    });
  }

  public GetRequestObject() {
    const reportingTileBase = this;

    const d: JQueryDeferred<IReportingRequestObject> = $.Deferred();

    if (reportingTileBase._RequestObject) {
      return d.promise(d.resolve(reportingTileBase._RequestObject));
    }

    LoadUser().done(() => {
      reportingTileBase._RequestObject = {
        conditions: {
          userId: CurrentUser.ID,
          tileContext: {
            type: "None",
            idList: ""
          }
        }
      };

      d.resolve(reportingTileBase._RequestObject);
    });

    return d.promise();
  }

  public GetTileObject() {
    const reportingTileBase = this;
    const d: JQueryDeferred<ITileInfo> = $.Deferred();

    if (reportingTileBase._TileObject) {
      return d.promise(d.resolve(reportingTileBase._TileObject));
    } else {
      new TDFRequest({
        url: `${reportingTileBase.UrlStub}/${reportingTileBase
          .AdvancedReportingTileSettings.ReportingTileID ||
          reportingTileBase.ID}`,
        type: "GET"
      })
        .MakeRequest()
        .done((tile: ITileInfo) => {
          d.resolve(tile);
        });
    }

    return d.promise();
  }

  public GetTileData(ForceGetLatest: boolean = false) {
    const reportingTileBase = this;

    const d: JQueryDeferred<any[]> = $.Deferred();

    if (
      !reportingTileBase._TileData ||
      (ForceGetLatest && reportingTileBase.AllowedToGetNewData())
    ) {
      reportingTileBase.GetRequestObject().done(requestObj => {
        if (reportingTileBase.PreviewMode) {
          reportingTileBase.GetTileObject().done(tile => {
            requestObj.tile = tile;

            new TDFRequest({
              url: `${reportingTileBase.UrlStub}/data`,
              type: "POST",
              data: requestObj
            })
              .MakeRequest()
              .done(response => {
                reportingTileBase._TileData = response;

                reportingTileBase.LastDataGrab = moment();

                d.resolve(response);
              });
          });
        } else {
          new TDFRequest({
            url: `${reportingTileBase.UrlStub}/${reportingTileBase
              .AdvancedReportingTileSettings.ReportingTileID ||
              reportingTileBase.ID}/data`,
            type: "POST",
            data: requestObj.conditions
          })
            .MakeRequest()
            .done(response => {
              reportingTileBase._TileData = response;

              reportingTileBase.LastDataGrab = moment();

              d.resolve(response);
            });
        }
      });
    } else {
      d.resolve(reportingTileBase._TileData);
    }

    return d.promise();
  }

  public ShowAdvanced() {
    const reportingTileBase = this;

    reportingTileBase.ShowAdvancedSettings = true;

    reportingTileBase.GetAllSettingsFormItems().done(() => {
      reportingTileBase.UpdateFormFields();
    });
  }

  public HideAdvanced() {
    const reportingTileBase = this;

    reportingTileBase.ShowAdvancedSettings = false;

    reportingTileBase.GetAllSettingsFormItems().done(() => {
      reportingTileBase.UpdateFormFields();
    });
  }

  protected abstract CreateAndAddControl(element);
  protected abstract ConvertSettingsToControlOptions(
    settings
  ): JQueryPromise<any>;

  protected GetConfigurationByObject() {
    const reportingTileBase = this;
    const d: JQueryDeferred<any> = $.Deferred();
    reportingTileBase.GetTileObject().done(Tile => {
      reportingTileBase
        .ConvertSettingsToControlOptions(Tile.Settings)
        .done(options => {
          reportingTileBase.Options = options;
          d.resolve();
        });
    });

    return d.promise();
  }

  protected GetConfigurationByID() {
    const reportingTileBase = this;
    const d = $.Deferred();

    new TDFRequest({
      url: `${reportingTileBase.UrlStub}/${reportingTileBase
        .AdvancedReportingTileSettings.ReportingTileID ||
        reportingTileBase.ID}`,
      type: "GET"
    })
      .MakeRequest()
      .done(response => {
        if (response) {
          reportingTileBase
            .ConvertSettingsToControlOptions(response.Settings)
            .done(options => {
              reportingTileBase.Options = options;
              d.resolve(true);
            });
        } else {
          d.resolve(false);
        }
      });

    return d.promise();
  }

  protected SetDataSource() {
    const reportingTileBase = this;

    reportingTileBase.Control.option(
      "dataSource",
      new dxDataSource(
        new dxCustomStore({
          load: () => {
            const d = $.Deferred();

            reportingTileBase.GetTileData().done(data => {
              d.resolve(data);
            });

            return d.promise();
          }
        })
      )
    );
  }

  protected UpdateFormFields(OverrideData?) {
    const ReportingTile = this;

    if (ReportingTile.Form) {
      const temp = ReportingTile.Form.option("onFieldDataChanged");
      ReportingTile.Form.option("onFieldDataChanged", () => { });

      const data = ReportingTile.Form.option("formData");

      ReportingTile.Form.option("items", []);

      ReportingTile.Form.option("formData", OverrideData || data);

      ReportingTile.Form.option("items", ReportingTile.FormFields);

      ReportingTile.Form.option("onFieldDataChanged", temp);
    }
  }

  // AARONS TODO: This should be changed to only build the popover and list once and then just affect the datasource of the list to change the items.
  protected GetBookmarksPopup(target: JQuery, listItems: any[]) {
    const BookmarkList = $("<div />")
      .dxList({
        onItemClick: e => {
          const target: JQuery = BookmarkPopover.option("target");

          const textInputFieldJQ: JQuery = target.find(
            "input.dx-texteditor-input"
          );

          const textInputFieldJS: any = document.getElementById(
            textInputFieldJQ.attr("id")
          );

          const startPos = textInputFieldJS.selectionStart;
          const endPos = textInputFieldJS.selectionEnd;

          textInputFieldJS.value =
            (textInputFieldJS.value as string).substring(0, startPos) +
            `{{${e.itemData}}}` +
            (textInputFieldJS.value as string).substring(endPos);
        },
        dataSource: listItems,
        scrollingEnabled: true,
        searchEnabled: true,
        searchTimeout: 500
      } as DevExpress.ui.dxListOptions)
      .dxList("instance");

    const BookmarkPopover = $("<div />")
      .dxPopover({
        contentTemplate: () => {
          const title = $("<h3 />")
            .text("Datasource Bookmarks")
            .css("margin", "unset");

          return $("<div />").append(title, "<hr />", BookmarkList.element());
        },
        onShown: e => {
          BookmarkList.option(
            "height",
            e.element
              .children()
              .children()
              .height() - 90
          );
        },
        onHidden: () => {
          BookmarkPopover.element().remove();
        },
        target,
        title: "Datasource Bookmarks",
        width: "300px",
        height: "350px"
      } as DevExpress.ui.dxPopoverOptions)
      .dxPopover("instance");

    $("body").append(BookmarkPopover.element());

    BookmarkPopover.show();
  }

  private AllowedToGetNewData() {
    const reportingTileBase = this;

    const lastDataGrab = moment(reportingTileBase.LastDataGrab);

    if (lastDataGrab) {
      const isAllowed = moment().isAfter(lastDataGrab.add(1, "seconds"));
      return isAllowed;
    } else {
      return true;
    }
  }

  private LoadUnknownTile(element: JQuery) {
    const div: JQuery = $("<div />")
      .css({
        width: "100%",
        height: "100%",
        "font-weight": "bold",
        "text-align": "center"
      })
      .text("Tile Not Found");

    element.append(div);
  }
}
